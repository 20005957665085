import {
  // SELECT_CATEGORY,
  // DESELECT_CATEGORY,
  SHOW_MORE_SKILLS
  // MOVE_CATEGORY
} from "./constants";

// export const selectCategory = category => ({ type: SELECT_CATEGORY, category });
// export const deselectCategory = { type: DESELECT_CATEGORY };
// export const moveCategory = (category, x, y, scale, rotation, time) => ({
//   type: MOVE_CATEGORY,
//   category,
//   x,
//   y,
//   scale,
//   rotation,
//   time
// });
export const showMoreSkills = category => ({
  type: SHOW_MORE_SKILLS,
  category
});
