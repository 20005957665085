import React from "react";
import ReactDOM from "react-dom";
// import "./index.css";
import App from "./components/App/App";
import * as serviceWorker from "./serviceWorker";
import createStore from "./store/createStore";
import { Provider } from "react-redux";
// import { getLocale } from "./helpers/locale";
// import { IntlProvider } from "react-intl";

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

const store = createStore();
// const locale = getLocale();

const render = Component => {
  return ReactDOM.render(
    <Provider store={store}>
      <Component />
    </Provider>,
    document.getElementById("root")
  );
};

render(App);

if (module.hot) {
  module.hot.accept("./components/App/App", () => {
    const NextApp = require("./components/App/App").default;
    render(NextApp);
  });
}
