import {
  SELECT_PORTFOLIO_ITEM,
  DESELECT_PORTFOLIO_ITEM,
  SET_PORTFOLIO_CATEGORY_FILTER,
  CLEAR_PORTFOLIO_ITEMS
} from "./constants";

const selectItem = item => ({ type: SELECT_PORTFOLIO_ITEM, item });
const deselectItem = () => ({ type: DESELECT_PORTFOLIO_ITEM });
const setCategoryFilter = category => ({
  type: SET_PORTFOLIO_CATEGORY_FILTER,
  category
});
const clearPortfolioItems = () => ({
  type: CLEAR_PORTFOLIO_ITEMS
});

export { selectItem, deselectItem, setCategoryFilter, clearPortfolioItems };
