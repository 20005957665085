import React, { Component } from "react";
import classNames from "classnames";
import experiments from "./experiments/experiments";
import { Link } from "react-router-dom";
import { TimelineLite } from "gsap";
import Navigation from "./Navigation";
import Helmet from "react-helmet";
import { addTranslations, l, t } from "../../helpers/i18n";
import ReactMarkdown from "react-markdown";

import "gsap/ScrollToPlugin";
import "./experiments.scss";

class Experiments extends Component {
  state = {
    menuOpen: false
  };

  componentDidMount() {
    this.scrollToExperiment();
  }

  componentDidUpdate(oldProps) {
    if (oldProps.match.params.slug !== this.props.match.params.slug) {
      this.scrollToExperiment();
    }
  }

  scrollToExperiment() {
    const { slug } = this.props.match.params;
    if (slug) {
      const experiment = experiments.find(e => e.slug === slug);
      if (experiment) {
        this.scrollTo(`.${slug}`);
      } else {
        this.scrollTo(".title-screen");
      }
    } else {
      this.scrollTo(".title-screen");
    }
  }

  scrollTo(slug) {
    const timeline = new TimelineLite();
    timeline.to(".experiment", 0.6, { opacity: 0.3 });
    timeline.to(this.refs.filmstrip, 0.6, {
      scrollTo: slug
    });
    timeline.to(slug, 0.6, { opacity: 1 });
  }

  onSidebarClick = event => {
    this.setState({ sidebarActive: !this.state.sidebarActive });
  };

  renderTitle() {
    return (
      <div className="experiment title-screen">
        <div className="title-container">
          <div className="content">
            <h2>{t("experiments.title")}</h2>
            <ReactMarkdown source={t("experiments.body")} />
          </div>
        </div>
      </div>
    );
  }

  renderMeta(experiment) {
    if (!this.isActive(experiment)) return null;
    return (
      <Helmet>
        <title>{l(experiment.name)}</title>
        <meta name="description" content={l(experiment.description)} />
      </Helmet>
    );
  }

  isActive(experiment) {
    return this.props.match.params.slug === experiment.slug;
  }

  renderExperimentDetail = experiment => {
    const Component = experiment.component;
    const active = this.isActive(experiment);
    const className = classNames("experiment", experiment.slug);
    return (
      <div className={className}>
        {this.renderMeta(experiment)}
        <Component active={active} />
        <div className="title">{l(experiment.name)}</div>
      </div>
    );
  };

  renderExperimentDetails() {
    return (
      <div className="experiment-details" ref="filmstrip">
        {this.renderTitle()}
        {experiments.map(this.renderExperimentDetail)}
      </div>
    );
  }

  renderNext(index) {
    const next = experiments[index + 1];
    if (next) {
      const url = `/experiments/${next.slug}`;
      return <Link to={url} className="next" />;
    }
    return null;
  }

  renderPrev(index) {
    if (!this.props.match.params.slug) return;
    const prev = experiments[index - 1];
    const url = prev ? `/experiments/${prev.slug}` : "/experiments";
    return <Link to={url} className="prev" />;
  }

  renderNextPrev() {
    const index = experiments.findIndex(
      e => e.slug === this.props.match.params.slug
    );
    return (
      <div className="navigation">
        {this.renderPrev(index)}
        {this.renderNext(index)}
      </div>
    );
  }

  toggleMenu = () => this.setState({ menuOpen: !this.state.menuOpen });

  renderNavigation() {
    const menuButtonClassName = classNames("menu-button", {
      active: this.state.menuOpen
    });

    return (
      <div>
        <button
          className={menuButtonClassName}
          onClick={this.toggleMenu}
          ref="link"
        >
          <div className="line line1" ref="line1" />
          <div className="line line2" ref="line2" />
          <div className="line line3" ref="line3" />
        </button>
        <Navigation toggleMenu={this.toggleMenu} open={this.state.menuOpen} />
      </div>
    );
  }

  render() {
    return (
      <div className="experiments">
        <Helmet>
          <body className="home" />
          <title>{t("experiments.title")}</title>
          <meta
            name="description"
            contents={t("experiments.body")
              .replace(/\n+/g, " ")
              .trim()}
          />
        </Helmet>
        {this.renderExperimentDetails()}
        {this.renderNextPrev()}
        {this.renderNavigation()}
      </div>
    );
  }
}

addTranslations({
  en: {
    experiments: {
      title: "Experiments",
      body: `
This is my personal playground where i store all my hobby
projects.

Most of them are made to test out a new technology.

You can use the navigation on the left side to select an
experiment to view and play with, or navigate with the arrows
    `
    }
  },
  nl: {
    experiments: {
      title: "Experimenten",
      body: `
Deze plek is voor mijn persoonlijke experiments, waar ik al mijn hobby projecten bewaar.

De meerendeel zijn gemaakt om een nieuwe technologie uit te proberen.

Je kan de menu aan de linker kant gebruiken om te navigeren door de experimenten of gebruik de pijlen om heen en weer te gaan.
    `
    }
  }
});

export default Experiments;
