import Ball from "./Ball";
import Torus from "./Torus";
import Shader from "./Shader";
import City from "./City";
import { l } from "../../../helpers/i18n";
import slugify from "../../../helpers/slugify";

const experiments = [
  {
    name: {
      en: "Ball with orbit controls",
      nl: "Bal met orbit controls"
    },
    description: {
      en: "Simple ball with a skybox. This was made with three.js",
      nl: "Simpel bal met een skybox. Gemaakt met three.js"
    },
    component: Ball
  },
  {
    name: {
      en: "Torus with orbit controls",
      nl: "Ringoppervlak met orbit controls"
    },
    description: {
      en: "Simple torus with a skybox. This was made with three.js",
      nl: "Simpel ringoppervlak met een skybox. Gemaakt met three.js"
    },
    component: Torus
  },
  {
    name: "Shader",
    description: {
      en: "An attempt on a custom vertex and fragment shader",
      nl: "En poging om een eigen vertex en fragment shader te schrijven"
    },
    component: Shader
  },
  {
    name: {
      en: "City",
      nl: "Stad"
    },
    description: {
      en:
        "A futuristic town with some fog. The model is loaded from an OBJ file. This was made with three.js",
      nl:
        "Een futuristisch stadje met wat mist. Het model wordt ingeladen van een OBJ file. Gemaakt met three.js"
    },
    component: City
  }
];

experiments.forEach(e => (e.slug = slugify(l(e.name))));
export default experiments;
