import React, { Component } from "react";
import PropTypes from "prop-types";
import { TweenLite, Power3 } from "gsap";
import { Link } from "react-router-dom";
import Skill from "./Skill";
import Helmet from "react-helmet";
import random from "../../helpers/random";
import Color from "color";
import { addTranslations, t, l } from "../../helpers/i18n";

// const minX = 0;
// const maxX = window.innerWidth - 250;
// const minY = 0;
// const maxY = window.innerHeight - 250;

class Category extends Component {
  static propTypes = {
    category: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    showMoreSkills: PropTypes.func.isRequired,
    active: PropTypes.bool.isRequired,
    x: null,
    y: null
  };

  state = {
    skillCount: 0,
    up: false,
    anim: null
  };

  componentDidMount() {
    const { x, y, scale, rotation } = this.getPosition();
    TweenLite.set(this.refs.category, {
      x,
      y,
      scale,
      rotation
    });
    this.move();
    if (this.props.active) {
      setTimeout(this.animateUp);
    }
  }

  getPosition = () => {
    const factor = Math.ceil(Math.sqrt(this.props.categories.length));
    const xunit = window.innerWidth / factor;
    const yunit = window.innerHeight / factor;
    const xi = this.props.index % factor;
    const yi = Math.floor(this.props.index / factor);
    const x = random(xi * xunit, (xi + 1) * xunit) - 250;
    const y = random(yi * yunit, (yi + 1) * yunit) - 250;
    const rotation = random(-45, 45);
    const scale = random(0.4, 0.6);
    const time = random(14, 20);
    const delay = random(0, 5);
    return { x, y, rotation, scale, time, delay };
  };

  move = () => {
    const { x, y, scale, rotation, time, delay } = this.getPosition();
    const anim = TweenLite.to(this.refs.category, time, {
      ease: Power3.easeInOut,
      x,
      y,
      rotation,
      scale,
      delay,
      force3d: true,
      onComplete: this.move
    });
    this.setState({ anim });
  };

  showMoreSkills(event, category) {
    this.props.showMoreSkills(category);
  }

  componentWillReceiveProps(props) {
    if (this.props.active !== props.active) {
      if (props.active) {
        this.animateUp();
      } else {
        this.animateDown();
      }
    }
  }

  upDone = () => this.setState({ up: true });

  onMouseOver = () => {
    if (!this.props.active) {
      TweenLite.set(this.refs.category, { z: 100 });
    }
  };

  onMouseOut = () => {
    if (!this.props.active) {
      TweenLite.set(this.refs.category, { z: 0 });
    }
  };

  animateUp = () => {
    const { x, y } = this.refs.category._gsTransform || {};
    this.setState({ x, y });
    if (this.state.anim) {
      this.state.anim.kill();
    }
    const rotationZ = random(-10, 10);
    TweenLite.to(this.refs.category, 0.7, {
      rotationY: 180,
      rotationZ,
      force3d: true,
      x: window.innerWidth / 2 - 250,
      y: window.innerHeight / 2 - 250,
      z: 1000,
      scale: 1,
      onComplete: this.upDone
    });
    TweenLite.to(this.refs.category, 0.7, {
      css: { boxShadow: "0px 0px 100px rgba(0,0,0,0.5)" }
    });
  };

  animateDown() {
    this.setState({ up: false });
    TweenLite.to(this.refs.category, 0.7, {
      rotationY: 0,
      rotationZ: this.props.rotation,
      x: this.state.x,
      y: this.state.y,
      force3d: true,
      z: 0,
      scale: 0.5,
      onComplete: this.move
    });
    TweenLite.to(this.refs.category, 0.7, {
      css: { boxShadow: "0px 0px 0px rgba(0,0,0,0.5)" }
    });
  }

  renderSkill(skill, index) {
    return <Skill skill={skill} key={index} up={this.state.up} />;
  }

  renderSkills(category) {
    const skills = category.visibleSkills.map((skill, index) =>
      this.renderSkill(skill, index)
    );
    return (
      <table className="skill-list list-unstyled">
        <tbody>{skills}</tbody>
      </table>
    );
  }

  renderPage() {
    const { category } = this.props;
    if (category.pageCount > 1) {
      return (
        <div className="page">
          {t("skills.category.page")}: {category.page} / {category.pageCount}
        </div>
      );
    }
  }

  renderLoadMoreButton() {
    const { category } = this.props;
    if (category.pageCount > 1) {
      return (
        <span
          className="btn btn-default load-more-button"
          onMouseDown={event => {
            this.props.showMoreSkills(category);
          }}
        >
          {t("skills.category.load_more")}
        </span>
      );
    }
  }

  renderMeta() {
    if (this.props.match.params.slug) {
      const { category } = this.props;
      return (
        <Helmet>
          <title>{l(category.name)}</title>
          <meta name="description" value={l(category.description)} />
        </Helmet>
      );
    }
  }

  render() {
    const { category } = this.props;
    return (
      <div
        className="category"
        ref="category"
        onMouseOver={this.onMouseOver}
        onMouseOut={this.onMouseOut}
      >
        {this.renderMeta()}
        <Link
          to={`${t("navigation.urls.skills")}/${category.slug}`}
          className="front"
          style={{ backgroundColor: Color(category.color).darken(0.5) }}
        >
          <div className="content">
            <h3 className="skill-count">{category.skills.length}</h3>
            <div className="name">{l(category.name)}</div>
          </div>
        </Link>
        <div
          className="back"
          ref="back"
          style={{ backgroundColor: category.color }}
        >
          <div className="content">
            <h3>{l(category.name)}</h3>
            {this.renderSkills(category)}
            {this.renderPage()}
            {this.renderLoadMoreButton()}
          </div>
        </div>
      </div>
    );
  }
}

addTranslations({
  en: {
    skills: {
      category: {
        load_more: "Show more",
        page: "Page"
      }
    }
  },
  nl: {
    skills: {
      category: {
        load_more: "Toon meer",
        page: "Pagina"
      }
    }
  }
});

export default Category;
