export default {
  name: "Retina display coming to the macbook pro",
  date: "2012/06/14",
  description: "The retina display is coming to the MacBook Pro.",
  content: `
The retina display is coming to the MacBook Pro’s. The high end of this series of laptops gets this amongst other things such as a new thinner casing and massive amounts of SSD space. This was announced on the 2 hours long keynote conference at WWDC 2012.

With this upgrade, the macbook pro will get a resolution of 2880x1800, which means that Apple claims the top position concerning resolution on the laptop market. They also claim to have reduced the “glare” with up to 75% “for some circumstances”, whatever that means.

The casing is only an astounding 0.71 inches thick, and the laptop weighs around 4 pounds. They also showed a look inside the machine, where it’s pretty tight. Apple engineers had to work hard, to fit the batterypacks in there. It still keeps a proposed 7 hours of battery life, but this is less in reality. It’s beautiful machine, also showing that apple is not just resting on their crown, but actively developing their products.

Furthermore IOS6 is out with a lot of exciting features, and Apple announced to step away from Google Maps and use their own mapping solution. Built from the ground up by Apple, and mashing up info from services such as Yelp. Mountain Lion was announced, which features a lot of stuff as well, including even more integration of stuff from iOS, which I strongly oppose.
`,
  tags: ["apple", "mac", "retina", "display"],
  category: "Setup"
};
