export default {
  name: "Memory upgrade",
  date: "2012/06/12",
  description: "Upgraded the memory in my MacBook Pro 13 inch.",
  content: `
I was trapped in “spinning beachball of death” limbo, ever since I upgraded my laptop to Lion. Overall I am already a bit unhappy with “ipad” like features of the new system, but what bugged me the most was the overall speed, especially when you forget to close all applications at shutdown, and Lion “helpfully” opens them all up upon boot. Terrible!

If there’s a thing that can really get my blood boiling, it’s waiting for a computer.

My laptop is a mid-2009 MacBook Pro, and I refuse to believe that this machine is deprectated after only 2 and a half years. For the price. it should have a longer lifespan

So i googled about it a bit, and found a few messages from people who upgraded the ram, and had succes with that. Right now ram upgrades are quite cheap, so I bought the double (4GB) to replace the two 1GB modules I already had installed.

It worked tremendously, not only for speed, but the capacity for multiple open applications had increased a lot of course. I can really recommend this, to people who are in the same situation as me. If you have a 2009 MacBook Pro, and upgraded from Snow leopard to Lion: Upgrade your RAM, it does wonders, and it’s relatively cheap.
`,
  tags: ["mac", "memory"],
  category: "Setup"
};
