import React, { Component } from "react";
import "./home.scss";
import Typer from "../Typer/Typer";
import { addTranslations, t } from "../../helpers/i18n";
import Helmet from "react-helmet";

class Home extends Component {
  componentDidMount() {
    document.body.classList.add("home");
  }
  componentWillUnmount() {
    document.body.classList.remove("home");
  }

  render() {
    return (
      <section className="splash">
        <Helmet>
          <body className="home" />
          <meta name="description" content={t("home.description")} />
        </Helmet>
        <div className="content">
          <div className="hero-unit" ref="hero">
            <h1>Jakob Lægdsmand</h1>
            <div className="payoffs">
              <Typer
                words={t("home.payoffs")}
                typingFrequency={80}
                cycleLength={4000}
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

addTranslations({
  en: {
    home: {
      description:
        "Jakob Lægdsmand is a freelance full-stack web developer and software architect with many years of experience.",
      payoffs: [
        "- Full stack web developer",
        "- 10+ years of experience",
        "- Skilled in many languages and frameworks",
        "- Social and communicative",
        "- Analytical and pragmatic",
        "- Available for hire!"
      ]
    }
  },
  nl: {
    home: {
      description:
        "Jakob Lægdsmand is een freelance full-stack web developer en software architect met ruime ervaring.",
      payoffs: [
        "- Full stack web developer",
        "- 10+ jaar ervaring",
        "- Capabel in veel talen en frameworks",
        "- Social en communicatief",
        "- Analytisch en pragmatisch",
        "- Beschikbaar voor opdrachten!"
      ]
    }
  }
});

export default Home;
