import about from "./about/about";
import location from "./location/location";
import portfolio from "./portfolio/portfolio";
import blog from "./blog/blog";
import skills from "./skills/skills";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  about,
  location,
  portfolio,
  blog,
  skills
});

export default rootReducer;
