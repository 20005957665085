import React, { Component } from "react";

const TYPING_FREQUENCY = 50;
const CYCLE_LENGTH = 10000;

class Typer extends Component {
  wordIndex = -1;
  letterIndex = 0;
  state = {
    contents: ""
  };

  componentDidMount() {
    this.start();
  }

  componentWillUnmount() {
    this.stop();
  }

  start = () => {
    this.letterIndex = 0;
    this.setState({ contents: "" }, () => {
      this.startTypingCycle();
      if (this.props.delete) {
        setTimeout(
          this.startDeletionCycle,
          (this.props.cycleLength || CYCLE_LENGTH) / 2
        );
      }
    });
  };

  stop = () => {
    if (this.deleteInterval) clearInterval(this.deleteInterval);
    if (this.typeInterval) clearInterval(this.typeInterval);
    if (this.typeTimeout) clearTimeout(this.typeTimeout);
    if (this.deleteTimeout) clearTimeout(this.deleteTimeout);
  };

  startTypingCycle = () => {
    this.typeWord();
    if (!this.typeInterval) {
      this.typeInterval = setInterval(
        this.typeWord,
        this.props.cycleLength || CYCLE_LENGTH
      );
    }
  };

  startDeletionCycle = () => {
    this.deleteWord();
    if (!this.deleteInterval) {
      this.deleteInterval = setInterval(
        this.deleteWord,
        this.props.cycleLength || CYCLE_LENGTH
      );
    }
  };

  typeWord = () => {
    if (this.wordIndex < this.props.words.length - 1) {
      this.wordIndex++;
    } else {
      this.wordIndex = 0;
    }
    this.letterIndex = 0;
    if (!this.props.delete) {
      this.setState({ contents: this.state.contents + "\n" });
    }
    this.typeLetter();
  };

  deleteWord = () => {
    this.deleteLetter();
  };

  typeLetter = () => {
    const word = this.props.words[this.wordIndex];
    const letter = word[this.letterIndex];
    if (this.letterIndex + 1 <= word.length) {
      this.setState({ contents: this.state.contents + letter });
      this.typeTimeout = setTimeout(
        this.typeLetter,
        this.props.typingFrequency || TYPING_FREQUENCY
      );
      this.letterIndex++;
    } else {
      if (!this.props.delete && this.wordIndex >= this.props.words.length - 1) {
        this.stop();
      }
      clearTimeout(this.typeTimeout);
    }
  };

  deleteLetter = () => {
    if (this.state.contents.length > 0) {
      this.setState({ contents: this.state.contents.slice(0, -1) });
      this.deleteTimeout = setTimeout(
        this.deleteLetter,
        this.props.typingFrequency || TYPING_FREQUENCY
      );
      if (this.letterIndex > 0) this.letterIndex--;
    } else {
      clearTimeout(this.deleteTimeout);
    }
  };

  renderSentence = (sentence, index) => <div key={index}>{sentence}</div>;

  render() {
    if (!this.props.delete) {
      return (
        <div>{this.state.contents.split("\n").map(this.renderSentence)}</div>
      );
    } else {
      return this.state.contents;
    }
  }
}

export default Typer;
