import "prismjs/components/prism-ruby";
import "prismjs/components/prism-markup-templating";
import "prismjs/components/prism-erb";

export default {
  name: "Use your powerlines for ethernet",
  date: "2013/01/08",
  description:
    "Why use energy and resources on laying out ethernet cables in your whole house, when there is already a net available: The powerlines! With a quite inexpensive solution it is possible to convert your all your powerplugs in the house to ethernet outlets. And you don't have to loose the power plug",
  content: `
Sometimes wi-fi just doesn’t cut it or don’t fit the bill. If you were ever thinking about laying down ethernet cables all over the house, forget about it! There is a smarter solution out there: It’s called “Powerline ethernet” or “Homeplug”.

It uses the powerlines that are running all over your house for ethernet traffic. That means every 220V power plug in your houses can be converted to an ethernet outlet!

You can buy an adapter that plugs into the power net. It has an ethernet port. The minimum setup requires two of these adapters. Basically all starter kits has this.

One of them will be plugged in the power socket close to your router or modem. You plug an ethernet cable from the out port on your modem or router and into the adapter.

On the other end of this line you plug another adapter, and plug an ethernet cable into the adapter and into a wireless router or just a router or switch or a computer.

The adapters filters out all ethernet traffic, and sends it via the cable to your devices.

Most adapters can be paired, and thus offers a secure connection, in case you are afraid somebody else in the vicinity also have homeplugs and might be leeching on your signal. The range of these things are around 300 meters, so it might be a proper worry. It also depends on the electrical hubs and installations in your neighbourhood.

The maximum connectionspeed around this time of writing is 500 mb/s (theoretically), around 150 mb/s in practice. These devices have gigabit connectors to accomodate this speed. Most of them also come with pass-through powerplugs, so you don’t lose the plug alltogether.

If you live in a house or apartment with more floors or very thick walls, this is a super elegant solution, without you having to install cables, that in essence are already there. Basically the electrical net has a lot of spare bandwidth, I heard that it is possible to install up to 255 of these devices in a normal house.

There are still some things to be aware of, but I encourage you to investigate that for yourself.

[Here](https://tweakers.net/categorie/791/powerline/producten/) is a list of devices currently for sale in the Netherlands.
`,
  tags: ["powerline", "ethernet"],
  category: "Setup"
};
