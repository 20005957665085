import React, { Component } from "react";
import StandardSceneManager from "./StandardSceneManager";
import OBJLoader from "./OBJLoader";
import MTLLoader from "./MTLLoader";
import { AmbientLight, Clock, Raycaster, Vector3, Fog, Color } from "three";
import PointerLockControls from "./PointerLockControls";
import "./city.scss";

class City extends Component {
  state = {
    loaded: false,
    loading: false
  };

  componentDidMount() {
    this.sceneManager = new StandardSceneManager(this.refs.container);
  }

  init = () => {
    if (this.state.loaded || this.state.loading) return;
    this.setState({ loading: true });
    const mLoader = new MTLLoader();
    const oLoader = new OBJLoader();
    mLoader.load("/models/obj/city/city.mtl", materials => {
      materials.preload();
      oLoader.setMaterials(materials);
      oLoader.load("/models/obj/city/city.obj", object => {
        this.object = object;
        this.sceneManager.add(object);
        // this.sceneManager.camera.position.set(100, 100, 100);
        // this.sceneManager.camera.lookAt(object.position);
        const light1 = new AmbientLight(0xffffff, 0.6);
        this.sceneManager.add(light1);
        this.setState({ loaded: true });

        this.addControls();

        this.clock = new Clock(true);
      });
    });
  };

  addControls = () => {
    var moveForward = false;
    var moveBackward = false;
    var moveLeft = false;
    var moveRight = false;
    var canJump = true;

    var prevTime = performance.now();
    var velocity = new Vector3();
    var direction = new Vector3();

    this.sceneManager.scene.background = new Color(0xffffff);
    this.sceneManager.scene.fog = new Fog(0xffffff, 0, 750);

    this.controls = new PointerLockControls(this.sceneManager.camera);

    this.refs.instructions.addEventListener(
      "click",
      () => {
        this.controls.lock();
      },
      false
    );

    this.controls.addEventListener("lock", () => {
      this.refs.instructions.style.display = "none";
      this.refs.blocker.style.display = "none";
    });

    this.controls.addEventListener("unlock", () => {
      this.refs.blocker.style.display = "block";
      this.refs.instructions.style.display = "";
    });

    this.sceneManager.add(this.controls.getObject());

    var onKeyDown = function(event) {
      switch (event.keyCode) {
        case 38: // up
        case 87: // w
          moveForward = true;
          break;

        case 37: // left
        case 65: // a
          moveLeft = true;
          break;

        case 40: // down
        case 83: // s
          moveBackward = true;
          break;

        case 39: // right
        case 68: // d
          moveRight = true;
          break;

        case 32: // space
          if (canJump === true) {
            velocity.y += 350;
          }
          canJump = false;
          break;
        default:
      }
    };

    var onKeyUp = function(event) {
      switch (event.keyCode) {
        case 38: // up
        case 87: // w
          moveForward = false;
          break;

        case 37: // left
        case 65: // a
          moveLeft = false;
          break;

        case 40: // down
        case 83: // s
          moveBackward = false;
          break;

        case 39: // right
        case 68: // d
          moveRight = false;
          break;
        default:
      }

      document.addEventListener("keydown", onKeyDown, false);
      document.addEventListener("keyup", onKeyUp, false);

      this.raycaster = new Raycaster(
        new Vector3(),
        new Vector3(0, -1, 0),
        0,
        10
      );

      this.onSceneManager.onAnim = () => {
        if (this.controls.isLocked === true) {
          this.raycaster.ray.origin.copy(this.controls.getObject().position);
          this.raycaster.ray.origin.y -= 10;

          var intersections = this.raycaster.intersectObjects(this.object);

          var onObject = intersections.length > 0;

          var time = performance.now();
          var delta = (time - prevTime) / 1000;

          velocity.x -= velocity.x * 10.0 * delta;
          velocity.z -= velocity.z * 10.0 * delta;

          velocity.y -= 9.8 * 100.0 * delta; // 100.0 = mass

          direction.z = Number(moveForward) - Number(moveBackward);
          direction.x = Number(moveLeft) - Number(moveRight);
          direction.normalize(); // this ensures consistent movements in all directions

          if (moveForward || moveBackward)
            velocity.z -= direction.z * 400.0 * delta;
          if (moveLeft || moveRight) velocity.x -= direction.x * 400.0 * delta;

          if (onObject === true) {
            velocity.y = Math.max(0, velocity.y);
            canJump = true;
          }

          this.controls.getObject().translateX(velocity.x * delta);
          this.controls.getObject().translateY(velocity.y * delta);
          this.controls.getObject().translateZ(velocity.z * delta);

          if (this.controls.getObject().position.y < 10) {
            velocity.y = 0;
            this.controls.getObject().position.y = 10;

            canJump = true;
          }

          prevTime = time;
        }

        this.controls.update(this.clock.getDelta());
      };
    };
  };

  componentDidUpdate() {
    this.update();
  }

  update() {
    if (this.props.active) {
      this.sceneManager.start();
    } else {
      this.sceneManager.stop();
    }
  }

  componentWillUnmount() {
    this.sceneManager.destroy();
    if (this.controls) {
      this.controls.dispose();
    }
  }

  render() {
    return (
      <div className="city">
        {!this.state.loaded &&
          !this.state.loading && (
            <button className="load-scene-button" onClick={this.init}>
              Load scene
            </button>
          )}
        {this.state.loading &&
          !this.state.loaded && <div className="loading">Loading &hellip;</div>}
        <div ref="container" style={{ height: "100%", width: "100%" }} />
        {this.state.loaded && (
          <div className="instructions" ref="instructions">
            Click here to move around!
          </div>
        )}
        {this.state.loaded && <div className="blocker" ref="blocker" />}
      </div>
    );
  }
}

export default City;
