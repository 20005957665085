import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Typer from "../Typer/Typer";
import Sidebar from "../Sidebar/Sidebar";
import Lang from "../Lang/Lang";
import { addTranslations, t } from "../../helpers/i18n";
import facebookSvg from "../../static/images/icons/facebook.svg";
import twitterSvg from "../../static/images/icons/twitter.svg";
import linkedInSvg from "../../static/images/icons/linkedin.svg";
import googlePlusSvg from "../../static/images/icons/google-plus.svg";
import youtubeSvg from "../../static/images/icons/youtube.svg";
import pinterestSvg from "../../static/images/icons/pinterest.svg";
import soundCloudSvg from "../../static/images/icons/soundcloud.svg";

import "./navigation.scss";

class Navigation extends Component {
  render() {
    const defaultProps = {
      activeClassName: "active",
      className: "navigation-link",
      onClick: this.props.toggleMenu
    };
    return (
      <div className="main-navigation">
        <Sidebar
          open={this.props.open}
          toggleMenu={this.props.toggleMenu}
          backdrop
          right
          zIndex={1010}
        >
          <h1 className="site-title">Jakob Lægdsmand</h1>
          <h3 className="site-subtitle">
            <Typer
              words={t("navigation.payoffs")}
              typingFrequency={50}
              cycleLength={10000}
              delete
            />
          </h3>
          <NavLink to={t("navigation.urls.home")} {...defaultProps}>
            {t("navigation.titles.home")}
          </NavLink>
          <NavLink to={t("navigation.urls.about")} {...defaultProps}>
            {t("navigation.titles.about")}
          </NavLink>
          <NavLink to={t("navigation.urls.portfolio")} {...defaultProps}>
            {t("navigation.titles.portfolio")}
          </NavLink>
          <NavLink to={t("navigation.urls.skills")} {...defaultProps}>
            {t("navigation.titles.skills")}
          </NavLink>
          <NavLink to={t("navigation.urls.blog")} {...defaultProps}>
            {t("navigation.titles.blog")}
          </NavLink>
          <NavLink to={t("navigation.urls.experiments")} {...defaultProps}>
            {t("navigation.titles.experiments")}
          </NavLink>
          <NavLink to={t("navigation.urls.contact")} {...defaultProps}>
            {t("navigation.titles.contact")}
          </NavLink>
          <Lang />
          <div className="bottom">
            <div className="address">
              <address>
                Jakob Lægdsmand
                <br />
                Waldeck Pyrmontkade 834
                <br />
                2518 KB Den Haag
                <br />
                NETHERLANDS
                <br />
                <br />
                +31 6 1444 7508
                <br />
                <br />
              </address>
              <p>
                <b>KvK:</b> 55085520
                <br />
                <b>BTW:</b> NL251487398B01
                <br />
              </p>
            </div>
            <div className="social-links">
              <a className="social-link facebook" href="#">
                <img alt="facebook" src={facebookSvg} width="30" height="30" />
              </a>
              <a className="social-link twitter" href="#">
                <img alt="twitter" src={twitterSvg} width="30" height="30" />
              </a>
              <a className="social-link linkedin" href="#">
                <img alt="linkedin" src={linkedInSvg} width="30" height="30" />
              </a>
              <a className="social-link google-plus" href="#">
                <img src={googlePlusSvg} width="30" height="30" />
              </a>
              <a className="social-link youtube" href="#">
                <img alt="youtube" src={youtubeSvg} width="30" height="30" />
              </a>
              <a className="social-link pinterest" href="#">
                <img
                  alt="pinterest"
                  src={pinterestSvg}
                  width="30"
                  height="30"
                />
              </a>
              <a className="social-link soundcloud" href="#">
                <img
                  alt="soundcloud"
                  src={soundCloudSvg}
                  width="30"
                  height="30"
                />
              </a>
            </div>
          </div>
        </Sidebar>
      </div>
    );
  }
}

addTranslations({
  en: {
    navigation: {
      payoffs: [
        "Freelance web developer",
        "Full-stack developer",
        "Software architect",
        "Digital problem solver",
        "Social / communicative nerd",
        "Coffee lover",
        "Cat owner"
      ],
      urls: {
        home: "/",
        about: "/about",
        portfolio: "/portfolio",
        skills: "/skills",
        blog: "/blog",
        experiments: "/experiments",
        contact: "/contact"
      },
      titles: {
        home: "Home",
        about: "About",
        portfolio: "Portfolio",
        skills: "Skills",
        blog: "Blog",
        experiments: "Experiments",
        contact: "Contact"
      }
    }
  },
  nl: {
    navigation: {
      payoffs: [
        "Freelance web developer",
        "Full-stack developer",
        "Software architect",
        "Digitale probleemoplosser",
        "Sociaal / communicatief nerd",
        "Koffie liebhebber",
        "Katteneigenaar"
      ],
      urls: {
        home: "/",
        about: "/over-mij",
        portfolio: "/portfolio",
        skills: "/ervaring",
        blog: "/blog",
        experiments: "/experimenten",
        contact: "/contact"
      },
      titles: {
        home: "Home",
        about: "Over mij",
        portfolio: "Portfolio",
        skills: "Ervaring",
        blog: "Blog",
        experiments: "Experimenten",
        contact: "Contact"
      }
    }
  }
});

export default Navigation;
