import React, { Component } from "react";
import Helmet from "react-helmet";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  selectItem,
  deselectItem,
  setCategoryFilter,
  clearPortfolioItems
} from "../../reducers/portfolio/actions";
import PortfolioItem from "./PortfolioItem";
import PortfolioLink from "./PortfolioLink";
import scrollSvg from "../../static/images/icons/scroll.svg";
import { TweenLite, Power3 } from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";

import "./portfolio.scss";

const SCROLL_OFFSET = 20;

class Portfolio extends Component {
  plugins = [ScrollToPlugin];
  state = {
    index: 0,
    scrolled: false,
    popupActive: false
  };

  componentDidMount() {
    if (this.props.match.params.slug) {
      const index = this.props.items.findIndex(
        i => i.slug === this.props.match.params.slug
      );
      if (index) {
        setTimeout(() => this.scrollToItem(index));
      }
    }
    window.addEventListener("keydown", this.onKeyDown);
  }

  componentWillUnmount = () => {
    window.removeEventListener("keydown", this.onKeyDown);
  };

  onKeyDown = event => {
    if (event.which === 38) {
      // up key
      this.scrollToItem(this.state.index - 1);
    } else if (event.which === 40) {
      // down key
      this.scrollToItem(this.state.index + 1);
    }
  };

  scrollToItem = index => {
    if (this.isScrolling) return;
    if (index < 0 || index >= this.props.items.length) return;
    const item = this.props.items[index];
    const { slug } = item;
    const time = Math.sqrt(Math.abs(this.state.index - index) * 0.5);
    this.setState({ index });
    console.log(time);
    this.isScrolling = true;
    TweenLite.to(this.refs.container, time, {
      scrollTo: `#${slug}`,
      backgroundColor: item.color,
      ease: Power3.easeOut,
      onComplete: () => (this.isScrolling = false)
    });
    this.props.history.push(`/portfolio/${slug}`);
  };

  checkScroll = event => {
    if (event.deltaY > SCROLL_OFFSET || event.deltaY < -SCROLL_OFFSET) {
      let { index } = this.state;
      if (event.deltaY > SCROLL_OFFSET) {
        index++;
      } else {
        index--;
      }
      this.scrollToItem(index);
    }
  };

  renderItem = (item, index) => (
    <PortfolioItem
      item={item}
      active={index === this.state.index}
      key={index}
    />
  );

  renderItems() {
    return (
      <div
        className="portfolio-items"
        ref="container"
        // onScroll={this.checkScroll}
        onWheel={this.checkScroll}
        // onKeyDown={this.onKeyDown}
        // tabIndex={0}
      >
        {this.props.items.map(this.renderItem)}
      </div>
    );
  }

  renderItemLink = (item, index) => (
    <PortfolioLink
      item={item}
      index={index}
      active={index === this.state.index}
      key={index}
      scrollToItem={this.scrollToItem}
    />
  );

  renderProgress() {
    return (
      <nav className="portfolio-links">
        {this.props.items.map(this.renderItemLink)}
      </nav>
    );
  }

  renderHint() {
    if (this.state.scrolled) return null;
    return <img alt="scroll down..." className="scroll-hint" src={scrollSvg} />;
  }

  render() {
    return (
      <div className="portfolio">
        <Helmet>
          <body className="home no-scroll" />
          <title>Portfolio</title>
          <meta
            name="description"
            content="Here you can find a collection of my latest projects"
          />
        </Helmet>
        {this.renderItems()}
        {this.renderProgress()}
        {this.renderHint()}
      </div>
    );
  }
}

const mapDispatchToProps = {
  selectItem,
  deselectItem,
  setCategoryFilter,
  clearPortfolioItems
};

const mapStateToProps = state => ({
  items: state.portfolio.items,
  error: state.portfolio.error,
  activeItem: state.portfolio.activeItem,
  categories: state.portfolio.categories,
  categoryFilter: state.portfolio.categoryFilter
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Portfolio)
);
