import React, { Component } from "react";
import PropTypes from "prop-types";
import { TweenLite } from "gsap";
import "./skills.scss";
import { l } from "../../helpers/i18n";

class Skill extends Component {
  static propTypes = {
    skill: PropTypes.object.isRequired
  };

  animate() {
    if (!this.props.skill.score) return;
    if (this.props.up) {
      TweenLite.to(this.refs.bar, 0.3, { width: `${this.props.skill.score}%` });
    } else {
      TweenLite.to(this.refs.bar, 0.3, { width: 0 });
    }
  }

  componentDidUpdate() {
    this.animate();
  }

  componentDidMount() {
    this.animate();
  }

  render() {
    const { skill } = this.props;
    let scoreElem;
    if (skill.score) {
      scoreElem = (
        <td>
          <div className="level">
            <div className="level-bar" ref="bar" style={{ width: 0 }} />
          </div>
        </td>
      );
    }
    return (
      <tr>
        <td className="name">{l(skill.name)}</td>
        {scoreElem}
      </tr>
    );
  }
}

export default Skill;
