import React, { Component } from "react";
import classNames from "classnames";

import "./sidebar.scss";

class Panel extends Component {
  onClick = () => this.props.toggleMenu();
  onContentClick = event => event.stopPropagation();
  render() {
    const className = classNames("sidebar", {
      active: this.props.open,
      backdrop: this.props.backdrop
    });
    const contentClassName = classNames(
      "content",
      this.props.left ? "left" : "right",
      {
        active: this.props.open
      }
    );
    const style = { zIndex: this.props.zIndex };
    return (
      <nav className={className} onClick={this.onClick} style={style}>
        <div className={contentClassName} onClick={this.onContentClick}>
          {this.props.children}
        </div>
      </nav>
    );
  }
}

export default Panel;
