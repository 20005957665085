export default {
  name: "Cyanogenmod and siyah kernel",
  date: "2012/04/24",
  description: "Cyanogenmod and the Siyah kernel makes a nice couple.",
  content: `
I always had a problem with the fact that my Samsung Galaxy S2 runs out of battery so quickly. I mean, charging every night (and sometimes more times a day) is OK, but is it really necessary. And yeah … I know that Android phones are more resource demanding because the multitasking is closer to real multitasking than what you find on the iPhone.

So I have Cyanogenmod 9 installed as my rom right now, upgrade to the newest nightly regurlarly, which is a breeze BTW: Download file, open CWM, flash the file in CWM, Wipe Dalvik cache and that’s it! I am really happy about Cyanogenmod so far, it works smoothly, and the number of bugs I have encountered is minimal.

I went on a crusade to find myself a solution to the battery problem, and i stumbled over the Siyah kernel, which has a branch for SGS2. You can download it from here. The Siyal kernel has a module called ExTweaks, where you can choose between three profiles: “Battery”, “Normal” and “Performance”. I of course went for “Battery” (though “Performance” seems terribly tempting somehow).

The general impression is that my battery life improved by at least 40%, maybe more.

Furthermore Siyah has the newest version of CWM Touch, which is touch enabled (and drag enabled for easier and quicker access to the menus). If you’ve messed around with the older CWM’s, then this is something you will really appreciate (instead of using the volume buttons and power buttons for navigation).

It’s really something I can recommend to people running Cyanogenmod 9 on the SGS2. Awesome work.
`,
  tags: ["cyanogenmod", "siyah", "android", "kernel"],
  category: "Setup"
};
