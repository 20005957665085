export default {
  name: "Redmine 2.0",
  date: "2012/06/12",
  description:
    "Redmine 2.0 is out, and it has support for ruby 1.9.3 and Rails 3.2.5",
  content: `
Redmine 2.0 - a new major version of the Redmine Project Management Software package - has been released and it supports ruby 1.9.3 and Rails 3.2.5. Oh boy, have i waited long for this day to come!!! Big KUDO’S to the team behind Redmine for this.

Features:

- Multiple projects support
- Flexible role based access control
- Flexible issue tracking system
- Gantt chart and calendar
- News, documents & files management
- Feeds & email notifications
- Per project wiki
- Per project forums
- Time tracking
- Custom fields for issues, time-entries, projects and users
- SCM integration (SVN, CVS, Git, Mercurial, Bazaar and Darcs)
- Issue creation via email
- Multiple LDAP authentication support
- User self-registration support
- Multilanguage support
- Multiple databases support
- … and much more!!

There are some drawbacks, though. The new version of Redmine is not backwards compatible with the plugins that ran on Redmine <= 1.4. That basically means that currently there are very few available plugins for this new release of Redmine. A few plugin vendors have ported their plugins to Redmine 2.0, and I suspect that in the coming time many others will do the same.

That being said, I love this package. It is so feature rich, that many people will not need plugins. As a project manager it is - IMHO - the best one around. I just installed it, and it is running smooth as a whistle. A big reason for me not to use Redmine in the past was the lack of support for the newer versions of Ruby and the Rails framework. Well, that is now in the past.

[Check out the website of redmine here](http://www.redmine.org/)
`,
  tags: ["ruby", "scm", "crm", "task management"],
  category: "Software"
};
