import {
  SELECT_BLOG_ITEM,
  DESELECT_BLOG_ITEM,
  SET_BLOG_CATEGORY_FILTER,
  SET_BLOG_TAG_FILTER,
  SET_BLOG_TIMELINE_FILTER
} from "./constants";

export const selectItem = item => ({ type: SELECT_BLOG_ITEM, item });
export const deselectItem = { type: DESELECT_BLOG_ITEM };
export const setCategoryFilter = category => dispatch => {
  // if (category) dispatch(resetFilters());
  dispatch({ type: SET_BLOG_CATEGORY_FILTER, category });
};
export const setTagFilter = tag => dispatch => {
  dispatch({ type: SET_BLOG_TAG_FILTER, tag });
};
export const setTimelineFilter = (year, month) => dispatch => {
  dispatch({ type: SET_BLOG_TIMELINE_FILTER, year, month });
};
export const resetFilters = () => dispatch => {
  dispatch(setCategoryFilter(null));
  dispatch(setTagFilter(null));
  dispatch(setTimelineFilter(null));
};
