import React, { Component } from "react";
import "./about.scss";
import { connect } from "react-redux";
import { toggleMuted, togglePaused } from "../../reducers/about/actions";
import Helmet from "react-helmet";
import { TimelineLite, Power3 } from "gsap";
import scrollSvg from "../../static/images/icons/scroll.svg";
import playSvg from "../../static/images/about/play.svg";
import pauseSvg from "../../static/images/about/pause.svg";
import muteSvg from "../../static/images/about/mute.svg";
import unmuteSvg from "../../static/images/about/unmute.svg";
import { addTranslations, t } from "../../helpers/i18n";

class About extends Component {
  timeline = new TimelineLite();

  componentDidMount() {
    this.makeTimeline();
    this.props.paused ? this.stop() : this.start();
    window.addEventListener("mousewheel", this.checkScroll);
    window.addEventListener("keydown", this.onKeyDown);
    window.addEventListener("blur", this.stop);
    window.addEventListener("focus", this.start);
  }

  componentDidUpdate(oldProps) {
    if (this.props.paused !== oldProps.paused) {
      this.props.paused ? this.stop() : this.start();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("mousewheel", this.checkScroll);
    window.removeEventListener("keydown", this.onKeyDown);
    window.removeEventListener("blur", this.stop);
    window.removeEventListener("focus", this.start);
  }

  checkScroll = event => {
    const { deltaY } = event;
    const newTime = this.timeline.time() - deltaY / 1000;
    if (newTime >= 0 && newTime <= this.refs.audio.duration) {
      this.timeline.time(newTime);
      this.refs.audio.currentTime = newTime;
    }
  };

  makeTimeline() {
    this.timeline = new TimelineLite();
    this.timeline.to(this.refs.wrapper, 100, { scale: 1.5 }, 0);
    this.timeline.from(this.refs.introduction, 1, { opacity: 0 }, 0);
    this.timeline.to(this.refs.introduction, 1, { opacity: 0 }, 4);
    this.timeline.to(this.refs.content, 90, { y: "-=50%" }, 5);
    this.timeline.to(this.refs.content, 8, { opacity: 0 }, 75);
    this.timeline.to(
      this.refs.container,
      8,
      { ease: Power3.easeIn, backgroundPosition: "50% 100%", opacity: 0 },
      80
    );
  }

  start = () => {
    if (this.props.paused) return;
    this.timeline.play();
    this.refs.audio.play();
  };

  stop = () => {
    this.timeline.pause();
    this.refs.audio.pause();
  };

  onKeyDown = event => {
    if (event.which === 32) {
      this.togglePaused();
    }
  };

  toggleMuted = () => {
    this.props.toggleMuted();
  };

  togglePaused = () => {
    this.props.togglePaused();
  };

  renderPauseButton() {
    const src = this.props.paused ? playSvg : pauseSvg;
    const alt = this.props.paused ? "play" : "pause";
    return (
      <button className="pause-button" onClick={this.togglePaused}>
        <img width="40" src={src} alt={alt} />
      </button>
    );
  }

  renderMuteButton() {
    const src = this.props.muted ? unmuteSvg : muteSvg;
    const alt = this.props.muted ? "unmute" : "mute";
    return (
      <button className="mute-button" onClick={this.toggleMuted}>
        <img width="40" src={src} alt={alt} />
      </button>
    );
  }

  renderStartButton() {
    if (!this.props.paused) return null;
    return (
      <button className="start-button" onClick={this.togglePaused}>
        <img width="200" src={playSvg} alt="start video" />
      </button>
    );
  }

  renderScrollTip() {
    if (this.props.paused) return null;
    return (
      <div className="scroll-tip">
        <img src={scrollSvg} alt="scroll to scrub" />
        {t("about.scroll_tip")}
      </div>
    );
  }

  render() {
    return (
      <div className="about-crop" onKeyDown={this.onKeyDown}>
        <Helmet>
          <body className="home no-scroll" />
          <title>{t("about.title")}</title>
          <meta name="description" content={t("about.description")} />
        </Helmet>
        <div className="introduction" ref="introduction">
          {t("about.introduction")}
        </div>
        <div className="about-wrapper" ref="wrapper">
          <div className="about-container" ref="container">
            <audio
              src="/audio/star-wars-2.mp3"
              autoPlay
              muted={this.props.muted}
              volume={this.props.volume}
              ref="audio"
            />
            <div className="about">
              <div className="about-content" ref="content">
                <h1 className="title">{t("about.body_title")}</h1>
                {t("about.body")}
              </div>
            </div>
          </div>
        </div>
        {this.renderStartButton()}
        <div className="buttons">
          {this.renderPauseButton()}
          {this.renderMuteButton()}
        </div>
        {this.renderScrollTip()}
      </div>
    );
  }
}

addTranslations({
  en: {
    about: {
      title: "About me",
      introduction: "A long time ago in a country far, far away …",
      description: "Here you can read more about me.",
      scroll_tip: "Use the scrollwhell if you are in a hurry …",
      body_title: "Episode I - About me",
      body: (
        <div>
          <p>
            My name is Jakob Lægdsmand. I was born in Copenhagen, Denmark. In
            1999 I moved to the Netherlands.
          </p>
          <p>
            I am a full-stack web developer, with a lot of experience in
            different languages and frameworks. Lately I've been doing a lot of
            front-end jobs, but I have broad experience in back-end programming
            as well.
          </p>
          <p>
            I have a great passion for structure and functionality, and care a
            lot about aesthetics of what I make. I don’t just write code, my
            work is always maticulate and precise.
          </p>
          <p>
            I love to invent simple solutions to complicated problems. Where
            most people will over-engineer a problem, I grab it at the roots and
            work on figuring out what would be the most pragmatic and valuable
            solution.
          </p>
          <p>
            The world of software is ever-changing and I have a passion for
            learning new languages and frameworks. In my free time I expand my
            knowledge.
          </p>
          <p>
            I write my code according to best practices, my code is clean and
            DRY. I write automated tests to make sure that everythings works and
            keep working as expected. I always document my code, so it remains
            accessible to other developers.
          </p>
          <p>
            Contrary to the typical image of nerds I am a very social person. I
            like sparring with a customer and through dialogue finding the
            solutions that makes both of us happy.
          </p>
          <p>
            I am also a musician by trade. In 2005 I graduated from the Royal
            Conservatory of the Hague with jazz-saxophone as my main instrument.
            I received a Master’s Degree of Performing Arts.
          </p>
        </div>
      )
    }
  },
  nl: {
    about: {
      title: "Over mij",
      introduction: "Lang geleden in een land ver, ver weg …",
      description: "Hier kan je meer over mij lezen.",
      scroll_tip: "Gebruik het scrollwiel als je haast hebt …",
      body_title: "Aflevering I - Over mij",
      body: (
        <div>
          <p>
            Mijn naam is Jakob Lægdsmand. Ik ben geboren in Kopenhagen in
            Denemarken. In 1999 vertrok ik naar Nederland.
          </p>
          <p>
            Ik ben een full-stack web developer, met veel ervaring in
            verschillende talen en frameworks. Ik heb veel ervaring als
            front-end developer, maar ook als back-end developer.
          </p>
          <p>
            Ik heb een grote passie voor structuur en functionaliteit,
            eaestethica is een groot onderdeel van alles wat ik doe. Ik schrijf
            niet alleen maar code, mijn code is altijd zorgvuldig en precies.
          </p>
          <p>
            Ik hou van simpele oplossingen voor complexe problemen. Waar de
            meeste engineers een probleem overdenk, probeer ik de essentie van
            het probleem te destilleren, en werk ik het meest pragmatische en
            waardevolle oplossing uit.
          </p>
          <p>
            De wereld van software veranderd continuu en ik heb een passie voor
            het leren van nieuwe talen en frameworks. In mijn vrije tijd verrijk
            ik mijn kennis online.
          </p>
          <p>
            Ik schrijf code volgens de gevestigde best practices. Mijn code is
            altijd DRY. Ik schrijf vaak geautomatiseerde tests om zeker te weten
            dat alles blijft functioneren zoals omschreven in de specs. Ik
            schrijf documentatie om mijn code beschikbaar te maken voor andere
            developers.
          </p>
          <p>
            Ik ben wat socialer angelegd dan je gemiddelde nerd. Ik hou van het
            sparren met een klant om en oplossing te vinden die ons allebei blij
            maakt.
          </p>
          <p>
            Ik ben ook opgeleid als musicus. In 2005 kreeg ik mijn Master's
            Degree of Performing Arts van het Koninklijk Conservatorium in Den
            Haag. Mijn hoofdvak was Jazz-saxofoon.
          </p>
        </div>
      )
    }
  }
});

const mapStateToProps = state => ({
  muted: state.about.muted,
  paused: state.about.paused,
  volume: state.about.volume
});

const mapDispatchToProps = {
  toggleMuted,
  togglePaused
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(About);
