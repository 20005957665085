import React, { Component } from "react";
import "./not-found.scss";
import { addTranslations, t } from "../../helpers/i18n";
import Helmet from "react-helmet";
import { TimelineLite, Expo } from "gsap";
import random from "../../helpers/random";
import { withRouter } from "react-router";
import explosionGif from "../../static/images/not-found/explode3.gif";
import button from "../../static/images/not-found/dont-panic.png";
import explosionMp3 from "../../static/audio/explosion.mp3";

const audio = new Audio(explosionMp3);

class NotFound extends Component {
  timeline = new TimelineLite({ paused: true });
  state = {
    exploded: false
  };

  renderChar = char => {
    if (char === " ") return <span>&nbsp;</span>;
    return <span>{char}</span>;
  };

  componentDidMount() {
    [...this.refs.title.childNodes].map(this.animateLetter);
    [...this.refs.subtitle.childNodes].map(this.animateLetter);
    [...this.refs.text.childNodes].map(this.animateLetter);
    this.animateButton();
    this.animateBackground();
  }

  animateLetter = letter => {
    const halfWidth = window.innerWidth / 2;
    const halfHeight = window.innerHeight / 2;
    const x = random(-halfWidth, halfWidth);
    const y = random(-halfHeight, halfHeight);
    const z = random(-500, 500);
    const rotationX = random(-360, 360);
    const rotationY = random(-360, 360);
    const rotationZ = random(-360, 360);
    const time = random(5, 10);
    const ease = Expo.easeOut;
    this.timeline.to(
      letter,
      time,
      {
        x,
        y,
        z,
        rotationX,
        rotationY,
        rotationZ,
        ease
      },
      0
    );
  };

  animateButton = element => {
    const halfWidth = window.innerWidth / 2;
    const halfHeight = window.innerHeight / 2;
    const x = random(-halfWidth, halfWidth);
    const y = random(-halfHeight, halfHeight);
    const rotationZ = random(-360, 360);
    const time = random(5, 10);
    const ease = Expo.easeOut;
    this.timeline.to(
      this.refs.button,
      time,
      {
        x,
        y,
        rotationZ,
        ease
      },
      0
    );
  };

  animateBackground() {
    const ease = Expo.easeOut;
    this.timeline.to(
      this.refs.container,
      1,
      {
        backgroundColor: "black",
        // force3d: true,
        ease
      },
      0
    );
  }

  explode = () => {
    if (this.state.exploded) return;
    this.setState({ exploded: true });
    this.timeline.play();
    this.timeline.eventCallback("onComplete", () => this.timeline.reverse());
    audio.play();
  };

  renderTitle() {
    const title = t("not_found.title");
    return [...title].map(this.renderChar);
  }

  renderSubtitle() {
    const subtitle = t("not_found.subtitle");
    return [...subtitle].map(this.renderChar);
  }

  renderText() {
    const text = t("not_found.text")(this.props.location.pathname);
    return [...text].map(this.renderChar);
  }

  render() {
    return (
      <div className="not-found" ref="container">
        <Helmet>
          <title>{t("not_found.title")}</title>
          {this.state.exploded && <body className="home" />}
          <meta name="description" content={t("not_found.description")} />
        </Helmet>
        <div className="content">
          <h1 className="title" ref="title">
            {this.renderTitle()}
          </h1>
          <h2 className="subtitle" ref="subtitle">
            {this.renderSubtitle()}
          </h2>
          <p className="text" ref="text">
            {this.renderText()}
          </p>

          <img
            width="300"
            src={button}
            alt={t("not-found.button")}
            ref="button"
            onClick={this.explode}
            className="button"
          />

          {this.state.exploded && (
            <img
              width="400"
              className="explosion"
              src={explosionGif}
              ref="explosion"
              alt="explosion"
            />
          )}
        </div>
      </div>
    );
  }
}

addTranslations({
  en: {
    not_found: {
      title: "404! Oh my!",
      subtitle: "Page not found!",
      description: "The page could not be found. Don't press the button!",
      text: path =>
        `The page, '${path}', could not be found. Please click back to return to the previous page.`,
      button: "Don't push this button!"
    }
  },
  nl: {
    not_found: {
      title: "404",
      subtitle: "Pagina niet gevonden",
      description:
        "De pagina kon niet gevonden worden. Niet klikken op de knopje!",
      text: path =>
        `De pagina, '${path}', kon niet gevonden worden. Klik op terug om terug te keren naar de vorige pagina.`,
      button: "Niet op deze knop drukken!"
    }
  }
});

export default withRouter(NotFound);
