export default {
  name: "Library folder in mac os x lion",
  date: "2012/02/19",
  description: "How to unhide the Library folder on Mac OS X Lion and newer.",
  content: `
The Library folder in your home folder is hidden by default in mac os x 10.7. Fortunately there is an easy fix for this. Just enter this command in the terminal:

\`\`\`
chflags nohidden ~/Library/
\`\`\`

… and voila! Your Library folder is now visible.

The folder will be invisible on next restart, so if you want to keep it around, your drag it to your favorites bar.
`,
  tags: ["mac", "os x"],
  category: "Setup"
};
