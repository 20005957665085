import "prismjs/components/prism-scss";

export default {
  name: "Iframes in opera",
  date: "2014/09/27",
  description:
    "Iframe elements are rendered on top of all other elements in opera, and doesn't listen to the z-index css property. This post gives a possible solution to this problem.",
  content: `
Opera is a great browser. It has excellent support for web standards, and is relatively small in size. It has an issue, though, that has been bugging me, and interupting my work on my editor for the last time

If you have an iframe element in an html page in opera, it will render on top of all other elements. This is only a problem, when you want to place controls on top of the iframe, such as dropdowns or buttons, which is a fairly standard use case.

The iframe element in opera doesn’t listen to the z-index css property. It is still rendered above all other elements. Otherwise, you might say, just give the element you want to place on top of the iframe a higher z-index, and then you dealt with the problem. Unfortunately, this doesn’t work.

### The solution

The solution to the problem is to make the iframe display as inline-block element. You can do this easily is css with:

\`\`\`css
iframe {
    display: inline-block;
}
\`\`\`

or you could alternatively use jQuery:

\`\`\`js
$("iframe").css("display", "inline-block");
\`\`\`

which would add the css as inline styles. This does carry some problems with it of course:

* First of all, the iframe element is obviously not an inline element
* The rendering of inline-block is buggy in some browsers
* When you render something as inline-block, trailing whitespace is respected, so whitespace may appear after the iframe element.

This problem makes me think about the problem of having elements on top of flash movies. This could be solved by setting the wmode parameter to “opaque”. Then the flash movie would mix in with the other page content. Unfortunately, for iframes, this is of course not possible. Or video elements in some versions of iOS, because the video element is rendered as a seperate layer directly from the video frame buffer, it would also be above all else. Visually the elements would be on top of the video, but they would not respond to click, etc. The only fix i found for this one was: “Don’t render anything above video elements on iOS”. Or IE, that rendered select boxes on top of all other elements. And os on, and so on.
`,
  tags: ["css", "js", "opera", "iframe"],
  category: "Programming"
};
