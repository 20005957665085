export default {
  name: "FuelPHP is great",
  date: "2013/04/12",
  description:
    "FuelPHP is an web application framework written in PHP. It needs PHP 5.3 or higher.",
  content: `
I just discovered FuelPHP, a relatively new framework on the scene. It is a framework built by a branch of developers that came from CodeIgniter and were a bit fed up with the dependency on PHP 4.

I started reading through the documentation, and it seemed a well-though over framework, so i decided to try to port one of my applications, that is built in Ruby on Rails to this platform. Just for the fun of it.

Almost immediately, it dawned on me how fast PHP really is. Standard request time is way under a tenth of a second, and that is just on my old laptop. It really makes the difference between Ruby on Rails and PHP very obvious. Rails needs so much time to load the framework into memory, and when this is done, it still feels a bit sluggish.

Don’t get me wrong: I love Rails! But sometimes I can get a bit frustrated about the speed. Especially when running tests or restarting the server etc. Using a php framework again made me realise how much time you spend waiting … which is basically lost time.

FuelPHP borrows a lot of concepts from Rails, for example te command line tool, migrations and the development console (I think they are the first to have a REPL style dev console in PHP, but I could be wrong). But that is really fantastic! Furthermore scaffolding is a big part of this framework, like it is in Ruby on Rails, and it can even go the other way: You can generate scaffold code from a database! And when you start thinking about this, it is quite cool. You could in fact take a legacy application, export the database, and run the scaffolding over that, and you would already have the basis for a fresh, clean app. Very nice. Not for everyone, but quite handy.

There are some great plugins in forms of packages, and that works great. It does use configuration over convention, but not completely. All packages look more or less similar in structure and they all live in the packages dir, but you still have to switch them on in the config. Which can be good or bad. It helps performance that not everything is loaded, but then you have to configure it. Some people prefer one, some people prefer another. I am trying not to take side in this …

The Auth package, which is FuelPHP’s builtin authentication plugin, has a extension SimpleAuth which will cover most of your authentiation needs. If you need more there are other plugins. One of the authors also wrote an extension to work with Oauth (for facebook etc.). SimpleAuth also has groups and roles bult in. It is not saved in the db, it is hardcoded in the config. It feels like a good solid basic solution. If you want it differently you can extend it or write your own driver, which the Auth class makes painless.

The framework has a built in profiler, which you also have to switch on in the config, and it really looks good. You can also have the database activity dumped here, but this has to be enabled elsewhere because it is a huge performance hog (well actually it didn;t really feel like that, but that’s what they claim). It sits as a tab in the bottom of your screen, and you can open it, when you need to view debug info.

The ORM module has basic functionality for CRUD operations, and it has support for relations, but this aspect leaves me wanting a lot more. Coming frm ActiveRecord in Rails, you really miss a lot here. Like has many, :through or delegating functions to relations, or the ability to keep on querying on a ResultSet after you got it. I am not saying that it is bad, but as a Rails programmer you are just really spoiled with the toolbox you have at hand.

I saw on the website of FuelPHP that the team is doing a rewrite of a lot of stuff for version 2.0, and they are going to implement another query building framework for the ORM module. The framework will change quite significantly for version 2.0, I am curious what is coming.

There is not really the same notion of an assets pipeline like you have in Rails 3.0. Your stylesheets and javascript files are not combined into a single file in production mode. But there is an Asset class that can help you with certain assets related things.

It also has form builders (under which there is a Fieldset class, which allows you to programmatically build a form), a Mail class, numerous DB drivers, Templating parsers, and lots more.

All in all it feels like a really mature framework, with a nice syntax and lots of possibilities. I also think that it can grow to become even better. This is one framework I am going to follow.
`,
  tags: ["php", "framework"],
  category: "Programming"
};
