import {
  SELECT_BLOG_ITEM,
  DESELECT_BLOG_ITEM,
  SET_BLOG_CATEGORY_FILTER,
  SET_BLOG_TAG_FILTER,
  SET_BLOG_TIMELINE_FILTER
} from "./constants";

import data from "./data";
import { uniq, flatten, map } from "lodash";

const getTags = items => uniq(flatten(map(items, "tags"))).sort();
const getCategories = items => uniq(map(items, "category")).sort();
const getTimeline = items =>
  uniq(
    items.map(i => {
      const [blogYear, blogMonth] = i.date.split("/");
      return `${blogYear}/${blogMonth}`;
    })
  )
    .sort()
    .reverse();

const dateSorter = (a, b) => new Date(b.date) - new Date(a.date);
data.sort(dateSorter);

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [SELECT_BLOG_ITEM]: (state, action) => ({
    ...state,
    activeItem: action.item
  }),
  [DESELECT_BLOG_ITEM]: (state, action) => ({
    ...state,
    activeItem: null
  }),
  [SET_BLOG_CATEGORY_FILTER]: (state, action) => {
    let visibleItems;
    if (action.category) {
      visibleItems = state.visibleItems.filter(
        item => item.category === action.category
      );
    } else {
      visibleItems = state.items;
    }
    const tags = getTags(visibleItems);
    const categories = getCategories(visibleItems);
    const timeline = getTimeline(visibleItems);
    return {
      ...state,
      visibleItems,
      tags,
      categories,
      timeline,
      categoryFilter: action.category
    };
  },
  [SET_BLOG_TAG_FILTER]: (state, action) => {
    let visibleItems;
    if (action.tag) {
      visibleItems = state.visibleItems.filter(item =>
        item.tags.includes(action.tag)
      );
    } else {
      visibleItems = state.items;
    }
    const tags = getTags(visibleItems);
    const categories = getCategories(visibleItems);
    const timeline = getTimeline(visibleItems);
    return {
      ...state,
      visibleItems,
      tags,
      categories,
      timeline,
      tagFilter: action.tag
    };
  },
  [SET_BLOG_TIMELINE_FILTER]: (state, action) => {
    let visibleItems, timelineFilter;
    if (action.year && action.month) {
      const month = ("0" + action.month).slice(-2);
      const regex = new RegExp(`^${action.year}\\/${month}`);
      visibleItems = state.visibleItems.filter(item => regex.test(item.date));
      timelineFilter = `${action.year}/${month}`;
    } else {
      visibleItems = state.items;
    }
    const tags = getTags(visibleItems);
    const categories = getCategories(visibleItems);
    const timeline = getTimeline(visibleItems);
    return {
      ...state,
      visibleItems,
      tags,
      categories,
      timeline,
      timelineFilter
    };
  }
};

const initialState = {
  items: data,
  activeItem: null,
  visibleItems: data,
  categories: getCategories(data),
  tags: getTags(data),
  timeline: getTimeline(data),
  categoryFilter: null,
  tagFilter: null,
  timelineFilter: null
};

// ------------------------------------
// Reducer
// ------------------------------------

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
