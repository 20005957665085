export default {
  name: "Favorite ruby gems",
  date: "2012/06/07",
  description: "A list of my favorite ruby gems thus far.",
  content: `
One of the many cool feature about ruby is the ability to reuse code written by yourself or others in the form of gems. A gem is like a plugin that add functionality to f.ex. your models, or provide helpers for in your views. You can install them easily into your existing application and start using them immediately. The ruby community shares code chunks like this through rubygems and github.

Since there are many things you do repeatedly for webapps (like pagination, user authentication etc.), it’s nice to be able to use code that has been tested and used many times before. No need to reinvent the wheel, and it keeps your code DRY.

In general the people who write gems and plugins take part in the social coding ecosystem promoted by github, so if you post bug reports they will usually be fixed, or you can do a pull request and fix a bug or implement a feature yourself.

I thought i would share my list of favorite gems with you guys.

- Devise - user authentication, signup and more
- Dragonfly - image processing on the fly
- CarrierWave - elegant batch image processing
- Highline - Timesaver for command line ruby scripts
- Mongoid - MongoDB Ruby Wrapper for a NoSQL database
- ActiveAdmin - Easy admin scaffolding via an easy to learn DSL
- CanCan - User authorization via a simple model interface and helper methods
- Coderay - Syntax Highlighting
- PaperTrail - Versioning of records, with rollback, whodunnit and more
- Kaminari - Easy and elegant pagination
- RedCarpet - Markdown processor
- Delayed Job - Scheduled and delayed processing of background tasks on the server
- Prawn - PDF Generation in Ruby
- nanoc - Static site compiler

Probably missing a few, but these are some of my favorites.
`,
  tags: ["ruby", "gem"],
  category: "Programming"
};
