export default {
  name: "iOS programming courses",
  date: "2012/06/06",
  description:
    "iTunes U (the free academy via iTunes) has some really nice courses from Stanford University on iPhone programming.",
  content: `
Here are some cool videos series explaining iOS development from scratch. They are taped lessons from Stanford University, taught by Paul Hegarty (the first two) and Alan Cannistraro and Josh Shaffer (the last link). You need to have iTunes installed do download them, but they are free of charge.

The two videos from 2010 are mainly about iOS 4, and the one middle video from 2011 covers iOS 5, which brought in a lot of changes, such as automatic reference counting and the storyboard.

- Developing Apps for iOS (2010)
- iPad and iPhone Application Development (2011)
- iPhone Application Development (2010)

Have fun. These videos cover loads of stuff, and should give you a really solid base in iOS development. They are basically complete programming courses from Stanford University.

**UPDATE**: These courses are no longer publically available on the internet. You have to access them va the iTunes U app on an iPad. Also there are of course newer versions available.
`,
  tags: ["ios", "objective-c", "iphone", "ipad"],
  category: "Programming"
};
