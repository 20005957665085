import React, { Component } from "react";
import PropTypes from "prop-types";
import BlogItemDetail from "./BlogItemDetail";
import { Link } from "react-router-dom";
import { TransitionGroup } from "react-transition-group";
import { TweenLite } from "gsap";

class BlogItem extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired
  };

  renderDetail() {
    if (this.props.item.slug === this.props.match.params.slug) {
      return <BlogItemDetail {...this.props} />;
    }
    return null;
  }

  componentWillEnter(callback) {
    TweenLite.from(this.refs.container, 0.5, {
      opacity: 0,
      onComplete: callback
    });
  }
  componentWillLeave(callback) {
    TweenLite.to(this.refs.container, 0.5, {
      opacity: 0,
      onComplete: callback
    });
  }

  render() {
    const item = this.props.item;
    const link =
      item.slug === this.props.match.params.slug
        ? "/blog"
        : `/blog/${item.slug}`;
    const localDate = new Date(item.date).toLocaleDateString("arab", {
      year: "numeric",
      month: "long",
      weekday: "long",
      day: "numeric"
    });
    return (
      <div className="blog-item" ref="container">
        <Link to={link} className="blog-link">
          <h3 className="name">{item.name}</h3>
          <div className="date">
            <em>{localDate}</em>
          </div>
          <div className="description">{item.description}</div>
        </Link>
        <TransitionGroup>{this.renderDetail()}</TransitionGroup>
      </div>
    );
  }
}

export default BlogItem;
