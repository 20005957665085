import React, { Component } from "react";
import { PlaneBufferGeometry, ShaderMaterial, Vector2, Mesh } from "three";
import StandardSceneManager from "./StandardSceneManager";
/* eslint-disable */
import vertexShader from "!raw-loader!./clouds-vertex.glsl";
import fragmentShader from "!raw-loader!./clouds-fragment.glsl";
/* eslint-enable */
class Shader extends Component {
  componentDidMount() {
    this.sceneManager = new StandardSceneManager(this.refs.container);

    this.uniforms = {
      u_time: { type: "f", value: 1.0 },
      u_resolution: { type: "v2", value: new Vector2() },
      u_mouse: { type: "v2", value: new Vector2() }
    };
    var geometry = new PlaneBufferGeometry(2, 2);
    const material = new ShaderMaterial({
      uniforms: this.uniforms,
      vertexShader: vertexShader.trim(),
      fragmentShader: fragmentShader.trim()
    });
    const mesh = new Mesh(geometry, material);

    this.sceneManager.add(mesh);

    this.update();
  }

  componentDidUpdate() {
    this.update();
  }

  update() {
    if (this.props.active) {
      this.sceneManager.start();
    } else {
      this.sceneManager.stop();
    }
  }

  componentWillUnmount() {
    this.sceneManager.destroy();
  }

  onMouseMove = e => {
    this.uniforms.u_mouse.value.x = e.pageX;
    this.uniforms.u_mouse.value.y = e.pageY;
  };

  render() {
    return (
      <div
        className="torus"
        ref="container"
        onMouseMove={this.onMouseMove}
        style={{ height: "100vh", width: "100vw" }}
      />
    );
  }
}

export default Shader;
