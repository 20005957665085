import React, { Component } from "react";
import request from "superagent";
import "./contact.scss";
import Helmet from "react-helmet";
import { addTranslations, t } from "../../helpers/i18n";

const defaultState = {
  name: "",
  email: "",
  subject: "",
  message: "",
  sending: false,
  errors: {}
};

class Contact extends Component {
  state = { ...defaultState, success: null };

  onSubmit = event => {
    if (event) {
      event.preventDefault();
    }
    const { name, email, subject, message } = this.state;
    const data = { name, email, subject, message };
    this.sendRequest(data);
  };

  sendRequest = async data => {
    this.setState({ sending: true });
    try {
      await request
        .post("/send")
        .type("json")
        .send(data);
      this.setState({ ...defaultState, success: true });
    } catch (error) {
      if (error.status === 422) {
        this.setState({
          errors: error.response.body.errors,
          success: null,
          sending: false
        });
      } else {
        this.setState({
          errors: {
            base: ["internal_error"]
          },
          sending: false,
          success: null
        });
      }
    }
  };

  onChange = event => {
    const element = event.target;
    const { name, value } = element;
    this.setState({ [name]: value });
  };

  renderError(field) {
    if (this.state.errors[field] && this.state.errors[field].length) {
      const className = field === "base" ? "base-error" : "error";
      const errors = this.state.errors[field].map((error, index) => (
        <div key={index}>{t(`contact.validations.${error}`)}</div>
      ));
      return <div className={className}>{errors}</div>;
    }
    return null;
  }

  renderSuccess() {
    if (this.state.success) {
      return <div className="success">{t(`contact.success`)}</div>;
    }
  }
  renderText() {
    return (
      <div className="contact-text">
        {/* <h2>{t("contact.get_in_touch.title")}</h2> */}
        <p>{t("contact.get_in_touch.body")}</p>
      </div>
    );
  }

  renderForm() {
    return (
      <form
        action="/send"
        method="post"
        onSubmit={this.onSubmit}
        autoComplete="off"
        ref="form"
      >
        {this.renderSuccess()}
        {this.renderError("base")}
        <div className="form-group">
          <label>{t("contact.form.name")}</label>
          <input
            className="form-control"
            type="text"
            name="name"
            value={this.state.name}
            onChange={this.onChange}
          />
          {this.renderError("name")}
        </div>
        <div className="form-group">
          <label>{t("contact.form.email")}</label>
          <input
            className="form-control"
            type="email"
            name="email"
            value={this.state.email}
            onChange={this.onChange}
          />
          {this.renderError("email")}
        </div>
        <div className="form-group">
          <label>{t("contact.form.subject")}</label>
          <input
            className="form-control"
            type="text"
            name="subject"
            onChange={this.onChange}
            value={this.state.subject}
          />
          {this.renderError("subject")}
        </div>
        <div className="form-group">
          <label>{t("contact.form.message")}</label>
          <textarea
            className="form-control"
            rows="10"
            name="message"
            onChange={this.onChange}
            value={this.state.message}
          />
          {this.renderError("message")}
        </div>
        <div className="form-group">
          <button
            type="submit"
            className="btn btn-primary btn-lg btn-block"
            disabled={this.state.sending}
          >
            {t("contact.form.send")}
          </button>
        </div>
      </form>
    );
  }

  renderTitle() {
    return (
      <div className="page-header">
        <h1>Contact</h1>
      </div>
    );
  }

  renderDetails() {
    return (
      <div className="details">
        <h2>Jakob Lægdsmand</h2>
        <address>
          Waldeck Pyrmontkade 834
          <br />
          2518KB Den Haag
          <br />
          NETHERLANDS
          <br />
          <br />
          T: +31 (6) 1444 7508
          <br />
          <br />
          KvK: 55085520
          <br />
          BTW: NL251487398B01
          <br />
        </address>
      </div>
    );
  }

  renderMap() {
    const src =
      "https://www.google.com/maps/embed?" +
      "pb=!1m18!1m12!1m3!1d2451.9135116653742!2d4.286563415791159!3d52.08130427973345!" +
      "2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c5b0d20110f061%3A0x91d69f543f94569!2s" +
      "Waldeck+Pyrmontkade+834%2C+2518+KB+Den+Haag!5e0!3m2!1snl!2snl!4v1476784617626";
    return <iframe src={src} frameBorder="0" allowFullScreen title="map" />;
  }
  render() {
    return (
      <div className="contact">
        <Helmet>
          <title>Contact</title>
          <body className="home" />
          <meta
            name="description"
            content="You can use the form on this page to contact me"
          />
        </Helmet>
        <div className="left">
          <div className="content">{this.renderMap()}</div>
        </div>
        <div className="right">
          <div className="content">
            {this.renderTitle()}
            {this.renderText()}
            {this.renderForm()}
          </div>
        </div>
      </div>
    );
  }
}

addTranslations({
  en: {
    contact: {
      validations: {
        required: "is required",
        email_address: "must be a valid email",
        forbidden: "must not contain dirty words",
        internal_error: "An internal error happened!"
      },
      get_in_touch: {
        title: "Get in touch",
        body: `I am always open to a conversation about your new project. Let's get
        together and chat! The coffee is on me. Use the form below to reach
        out.`
      },
      form: {
        name: "Name",
        email: "Email",
        subject: "Subject",
        message: "Message",
        send: "Send"
      },
      success: "Your message has been sent! See you soon!"
    }
  },
  nl: {
    contact: {
      validations: {
        required: "is vereist",
        email_address: "moet een geldige email zijn",
        forbidden: "mag geen vieze woorden bevatten",
        internal_error: "Er is een interne fout opgetreden"
      },
      get_in_touch: {
        title: "Neem contact op",
        body: `Ik sta altijd open voor een conversatie over jouw nieuwe project. Laten we afspreken en kletsen! Ik betaal de koffie. Gebruik de form hieronder om contact te leggen.`
      },
      form: {
        name: "Naam",
        email: "Email",
        subject: "Onderwerp",
        message: "Bericht",
        send: "Verstuur"
      },
      success: "Jouw bericht is verzonden! Tot binnenkort!"
    }
  }
});

export default Contact;
