import {
  CubeGeometry,
  MeshFaceMaterial,
  Mesh,
  MeshBasicMaterial,
  BackSide,
  ImageUtils
} from "three";

export default () => {
  const imagePrefix = "/images/experiments/skybox/skybox_";
  const directions = ["px", "nx", "py", "ny", "pz", "nz"];
  const imageSuffix = ".jpg";

  const materialArray = [];
  for (var i = 0; i < 6; i++)
    materialArray.push(
      new MeshBasicMaterial({
        map: ImageUtils.loadTexture(imagePrefix + directions[i] + imageSuffix),
        side: BackSide
      })
    );

  const skyGeometry = new CubeGeometry(500, 500, 500);
  const skyMaterial = new MeshFaceMaterial(materialArray);
  const skyBox = new Mesh(skyGeometry, skyMaterial);
  // skyBox.rotation.x += Math.PI / 2;
  return skyBox;
};
