import {
  // SELECT_CATEGORY,
  // DESELECT_CATEGORY,
  SHOW_MORE_SKILLS,
  MAX_VISIBLE_SKILLS
  // MOVE_CATEGORY
} from "./constants";

import data from "./data";

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  // [SELECT_CATEGORY]: (state, action) => ({
  //   ...state,
  //   activeCategory: action.category
  // }),
  // [DESELECT_CATEGORY]: (state, action) => ({
  //   ...state,
  //   activeCategory: null
  // }),
  [SHOW_MORE_SKILLS]: (state, action) => {
    const { category } = action;
    const index = state.categories.indexOf(category);
    let page = category.page;
    if (page === category.pageCount) {
      page = 1;
    } else {
      page += 1;
    }
    const end = page * MAX_VISIBLE_SKILLS;
    const begin = end - MAX_VISIBLE_SKILLS;
    const visibleSkills = category.skills.slice(begin, end);
    const newCategory = { ...action.category, page, visibleSkills };
    return {
      ...state,
      categories: [
        ...state.categories.slice(0, index),
        newCategory,
        ...state.categories.slice(index + 1)
      ]
    };
  }
  // [MOVE_CATEGORY]: (state, action) => {
  //   const categories = state.categories.map(
  //     c =>
  //       c === action.category
  //         ? {
  //             ...c,
  //             x: action.x,
  //             y: action.y,
  //             scale: action.scale,
  //             rotation: action.rotation,
  //             time: action.time
  //           }
  //         : c
  //   );
  //   return { ...state, categories };
  // }
};

for (const category of data) {
  category.page = 1;
  category.pageCount = Math.ceil(category.skills.length / MAX_VISIBLE_SKILLS);
  category.visibleSkills = category.skills.slice(0, MAX_VISIBLE_SKILLS);
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  categories: data
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
