export default {
  name: "Crontab expressions",
  date: "2012/05/19",
  description:
    "You can use crontab expression in your crontab file. It makes the syntax a little more readable and more agile.",
  content: `
When creating tasks in crontab you have to use a specific syntax. It’s quite easy to learn, but you might like or dislike the syntax. Usually you use the crontab for the same things, either executing things on a daily basis, or on weekly/monthly basis. Personally, with the old syntax, I always forget the ordering of minutes, hours, day of week and so on. So the expressions listed below can be handy.

The commands are:

\`\`\`cron
@reboot # run at boot and reboot only
@yearly # run at midnight Jan 1 each year (equiv to 0 0 1 1 *)
@annually # run at midnight Jan 1 each year (equiv to 0 0 1 1 *)
@monthly # run at midnight on the first day of each month (equiv to 0 0 1 * *)
@weekly # run at midnight each Sunday (equiv to 0 0 * * 0)
@daily # run at midnight each day (equiv to 0 0 * * *)
@midnight # run at midnight each day (equiv to 0 0 * * *)
@hourly # run on the first second of every hour (equiv to 0 * * * *)
\`\`\`

Example of normal cron syntax:

\`\`\`cron
0 0 * * * /path/to/command
\`\`\`

This example would execute a command every night around at midnight. So instead of the above example you could use:

\`\`\`cron
@daily /path/to/command
\`\`\`

or

\`\`\`cron
@midnight /path/to/command
\`\`\`
`,
  tags: ["crontab", "linux"],
  category: "Server"
};
