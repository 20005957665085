import {
  SELECT_PORTFOLIO_ITEM,
  DESELECT_PORTFOLIO_ITEM,
  SET_PORTFOLIO_CATEGORY_FILTER,
  CLEAR_PORTFOLIO_ITEMS
} from "./constants";

import data from "./data";

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [SELECT_PORTFOLIO_ITEM]: (state, action) => ({
    ...state,
    activeItem: action.item
  }),
  [DESELECT_PORTFOLIO_ITEM]: (state, action) => ({
    ...state,
    activeItem: null
  }),
  [SET_PORTFOLIO_CATEGORY_FILTER]: (state, action) => {
    // let items;
    // if (action.category) {
    //   items = data.filter(item => item.categories.includes(action.category));
    // } else {
    //   items = data;
    // }
    return {
      ...state,
      // items,
      categoryFilter: action.category
    };
  },
  [CLEAR_PORTFOLIO_ITEMS]: (state, action) => {
    return {
      ...state,
      items: []
    };
  }
};

// ------------------------------------
// Reducer
// ------------------------------------
// const categories = [];
// data.forEach(item => {
//   item.categories.forEach(category => {
//     if (!categories.includes(category)) {
//       categories.push(category);
//     }
//   });
// });

const initialState = {
  items: data,
  activeItem: null,
  // categories,
  categoryFilter: null
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
