import slugify from "../../helpers/slugify";
import Color from "color";

const portfolioItems = [
  {
    name: "RTL Nederland",
    // slug: "rtl-nederland",
    color: "rgb(0,0,0)",
    description: {
      en: `
RTL Nederland is one of the biggest entertainmant and media providers in the benelux area.
With famous TV channels such as RTL 4, RTL 5, RTL 7, RTL 8, RTL Z, RTL Lounge, RTL Crime,
RTL Telekids, RTL XL and Videoland it serves a very big audience with popular TV shows.

My role at this company was as scrum master for the RTL "Zendersites" team, which mainly dealt
with the traditional TV offerings of this company, as opposed to all subscription based on-demand content.
We developed and maintained a website initially made with Angular, which we succesfully converted to React for
immediate performance and development speed gains.

We also built many loose products, that were used by other teams in the organisation,
amongst them a cookiewall, load testing tools, a "swiss army-knife" javascript library and more.

The API's that we needed for this website were many and scattered over the entire organsation. We decided that it would
be a good idea to build our own caching/proxy layer that could gather and mould the data into a format that were more suited
to our needs.
      `,
      nl: `
RTL Nederland is een van de grootste media- en entertainmentproviders in de Benelux. Met bekende TV-zenders zoals RTL4, RTL5, RTL7
RTL 8, RTL Z, RTL Lounge, RTL Crime, RTL Telekids, RTL XL en Videoland
      `
    },
    coverImage: "rtl/logo.jpg",
    tags: [
      "website",
      "node.js",
      "javascript",
      "react",
      "css",
      "scrum",
      "angular"
    ],
    images: [
      "rtl/trending.png",
      "rtl/program1.png",
      "rtl/program2.png",
      "rtl/episodes.png",
      "rtl/search1.png",
      "rtl/search2.png",
      "rtl/az.png"
    ]
  },
  {
    name: "Sketchdrive",
    color: "#0099e5",
    // slug: "sketchdrive",
    description: {
      en: `
Sketchdrive is based on a belief that learning design skills requires more than watching online videos.
You will need to make something and than receive expert feedback to improve your work and help you develop your critical eye.
In Sketchdrive, just like in a studio, everything is visual.
Students upload photos or screenshots of their work and instructors give visual feedback by drawing their critique directly on top.

My role on this project is as developer, and that of an advisor to the board of directors. I have developed many new features,
and rewrote the core of the application to make it a Single Page Applcation based on an API and React.

The API is made in Ruby on Rails, and the view layer is handled by React. It uses a Postgresql database, and integrates with Stripe.
Many core functionalities are queued as background jobs to make the application feel more performant and snappy. It is hosted on Heroku.
      `,
      nl: ``
    },
    coverImage: "sketchdrive/logo.png",
    tags: [
      "website",
      "mobile app",
      "ruby on rails",
      "scss",
      "javascript",
      "react"
    ],
    smallImages: true,
    images: [
      "sketchdrive/home.png",
      "sketchdrive/home-projects-menu.png",
      "sketchdrive/course.png",
      "sketchdrive/course2.png",
      "sketchdrive/topic.png",
      "sketchdrive/filter.png",
      "sketchdrive/lightbox.png",
      "sketchdrive/feedback.png",
      "sketchdrive/fullscreen.png",
      "sketchdrive/fullscreen-feedback.png",
      "sketchdrive/upload.png"
    ]
  },
  {
    name: "Buku-Larrŋgay Mulka | Yirrkala Art Centre",
    // slug: "buku",
    color: "rgb(239,237,233)",
    description: {
      en: `
Buku-Larrnggay Mulka Centre is the Indigenous community controlled art centre of Northeast Arnhem Land.
Located in Yirrkala, a small Aboriginal community on the northeastern tip of the Top End of the Northern Territory,
approximately 700km east of Darwin.

Still on the same site but in a greatly expanded premises Buku-Larrnggay Mulka Centre now consists of two divisions;
the Yirrkala Art Centre which represents Yolngu artists exhibiting and selling contemporary art
and The Mulka Project which acts as a digital production studio and archiving centre incorporating the Museum.

The website was built using my own CMS, and integrates content and shop + checkout under one integrated package.

The design was made by Kai Brethouwer.
`,
      nl: `
Buku-Larrnggay Mulka Centre is een cultureel centrum in Northeast Arnhem Land in Australie. Het bevindt zich in Yirrkala, een kleine aboriginal gemeenschap in het noordoostelijke gedeelte van Northern Territory, ongeveer 700 kilometer ten oosten van Darwin.

Buku-Larrnggay Mulka Centre bestaat uit twee gedeeltes:

1. Het Yirrkala Art Centre waar aboriginal kunstenaars hun kunst vertoonen en verkopen.
2. The Mulka Project, een digitale productie- en opname studio en archief.
Deze website heeft mijn eigen cms als backend en integreert inhoud en webwinkel + checkout onder een cms. De checkout gebruikt Stripe als backend.

Design was gemaakt door Kai Brethouwer.
`
    },
    images: [
      "buku/01-homepage.png",
      "buku/02-landing-page.png",
      "buku/03-grid.png",
      "buku/04-textpage.png",
      "buku/05-news-detail.png",
      "buku/06-news-detail2.png",
      "buku/07-news-detail3.png"
    ],
    coverImage: "buku/logo2.svg",
    tags: [
      "website",
      "webshop",
      "ruby on rails",
      "scss",
      "javascript",
      "stripe"
    ]
  },
  {
    name: "Creative Cowboy",
    // slug: "creative-cowboy",
    color: "rgb(0,0,0)",
    description: {
      en: `
Creative cowboy films, an award winning company, make films and publish blogs, e-books and e-magazines about art and culture and nature. They work in some of the remotest places on earth and with some of the world’s most significant artists.

On their site you stream high quality video (either via a subscription or via a pay-per-view model) and read more information about their running and upcoming projects. The site also has a store where you can buy hardcopies of the documentaries.

Designed by Kai Brethouwer.
      `,
      nl: `
Creative Cowboy Films, een bedrijf wat veel awards heeft gewonnen, maakt films en publiceert artikelen, e-books en e-magazines over kunst, kultuur en natuur. Zij werken in de meest afgelegen plekken op aarde en met werelds voornaamste artiesten.

Bezoekers kunnen video’s in hoge kwaliteit streamen (via een abonnement of via pay-per-view) en meer informatie krijgen over hun lopende en opkomende projecten. Er is ook een webwinkel waar men DVDs kan aanschaffen van de documentaries.

Design gemaakt door Kai Brethouwer.
      `
    },
    coverImage: "creative-cowboy/logo2.jpg",
    tags: [
      "website",
      "webshop",
      "video streaming",
      "on-demand content",
      "ruby on rails",
      "scss",
      "javascript"
    ],
    images: [
      "creative-cowboy/01-home.png",
      "creative-cowboy/02-home2.png",
      "creative-cowboy/03-move-detail.png",
      "creative-cowboy/04-blog.png",
      "creative-cowboy/05-magazine.png",
      "creative-cowboy/06-magazine2.png",
      "creative-cowboy/07-shop.png",
      "creative-cowboy/08-slider.png",
      "creative-cowboy/09-slider-enlarged.png",
      "creative-cowboy/10-login.png",
      "creative-cowboy/11-search.png"
    ]
  },
  {
    name: "Booking cares",
    // slug: "booking-cares",
    color: Color("rgb(5,55,126)")
      .darken(0.4)
      .rgb()
      .toString(),
    description: `
Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Cras justo odio, dapibus ac facilisis in, egestas eget quam.
Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vitae elit libero, a pharetra augue. Aenean eu leo quam.
Pellentesque ornare sem lacinia quam venenatis vestibulum. Morbi leo risus, porta ac consectetur ac,
vestibulum at eros. Maecenas sed diam eget risus varius blandit sit amet non magna.
    `,
    coverImage: "booking/logo.svg",
    tags: [
      "website",
      "framework",
      "python",
      "django",
      "scss",
      "javascript",
      "automation"
    ],
    images: [
      "booking/home1.png",
      "booking/info.png",
      "booking/popup.png",
      "booking/text.png",
      "booking/video.png"
    ]
  },
  {
    name: "NCJ - Excessief huilen",
    // slug: "ncj",
    color: "rgb(184,43,106)",
    description: {
      en: `
“Excessief huilen” means Excessive crying in Dutch. This e-learning aims to teach mothers and professionals working with mothers about the effects and important issues of excessive crying.

This e-learning was built together with Tinqwise and Studio Blink. All the content was written by Tinqwise.
      `,
      nl: `
“Excessief huilen” is een e-learning over de oorzaken en symptomen van excessief huilen van babies en over de gevolgen voor ouders van deze kinderen. De doelgroep bestaat uit professionals die ondersteuning bieden aan de vaders en moeders van deze kinderen.

Deze e-learning was gemaakt in samenwerking met Tinqwise en Studio Blink.      `
    },
    coverImage: "ncj/logo2.jpg",
    tags: ["e-learning", "ruby", "nanoc", "scss", "javascript", "scorm"],
    smallImages: true,
    images: [
      "ncj/01-home.png",
      "ncj/02-menu.png",
      "ncj/03-text.png",
      "ncj/04-video.png",
      "ncj/05-hotspot.png",
      "ncj/06-check.png",
      "ncj/07-open.png",
      "ncj/08-quiz.png"
    ]
  },
  {
    name: "NCJ - Pesten",
    // slug: "ncj",
    color: Color("rgb(184,43,106)")
      .darken(0.2)
      .rgb()
      .toString(),
    description: {
      en: `
This e-learning is about the effects that teasing and group-pressure can have on children, both the victims and the perpetrators.

This e-learning was built together with Tinqwise. All the content was written by Tinqwise.
      `,
      nl: `
Deze e-learning gaat over de gevolgen van pesten en groepsdruk op de ontwikkeling van kinderen. Er wordt gebruik gemaakt van verschillende werkvormen zoals quizvragen, textpagina’s met plaatjes en grafieken. De e-learning is SCORM compatibel.

Deze e-learning was gemaakt in samenwerking met Tinqwise
      `
    },
    coverImage: "ncj/logo2.jpg",
    tags: ["e-learning", "ruby", "nanoc", "scss", "javascript", "scorm"],
    smallImages: true,
    images: [
      "ncj2/01-home.png",
      "ncj2/02-menu.png",
      "ncj2/03-text.png",
      "ncj2/04-case.png",
      "ncj2/05-check.png",
      "ncj2/06-hotspot1.png",
      "ncj2/07-drag.png",
      "ncj2/08-multi.png",
      "ncj2/08-true-false.png"
    ]
  },
  {
    name: "SVH - Raad & Daad",
    // slug: "svh",
    color: "rgb(73,170,225)",
    description: {
      en: `
Raad & Daad is an e-learning module, teaching about safety and criminality in the hotel and catering sector. The module has magazine style text presentations, case videos and interview videos, mini-quizzes, links and tip pages. The design is modern and fresh.

This e-learning was made in collaboration with Tinqwise. Tinqwise is a company specialised in e-learnings and they have made many fine productions. For more info check out their website
      `,
      nl: `
Raad & Daad is een e-learning module over veiligheid en criminaliteit in de horeca. De module gebruikt magazine-achtige tekstpagina’s, voorbeeld video’s, interviews, mini-quizzen en link pagina’s. Het ontwerp is modern en fris.

Deze e-learning is gemaakt in samenwerking met Tinqwise.
      `
    },
    coverImage: "svh1/logo.jpg",
    tags: ["e-learning", "ruby", "nanoc", "scss", "javascript", "scorm"],
    smallImages: true,
    images: [
      "svh1/01-start.png",
      "svh1/02-mainmenu.png",
      "svh1/03-intro.png",
      "svh1/04-submenu.png",
      "svh1/05-submenu2.png",
      "svh1/06-interview.png",
      "svh1/07-quiz.png",
      "svh1/08-text.png",
      "svh1/09-links.png",
      "svh1/10-tips.png",
      "svh1/11-video.png"
    ]
  },
  {
    name: "SVH - Tel uit je winst",
    // slug: "svh",
    color: Color("rgb(73,170,225)")
      .fade(0.2)
      .rgb()
      .toString(),

    description: {
      en: `
“Tel uit je winst” is an e-learning module that focuses on the number crunching parts of driving a business in the hotel and catering sector. It is aimed at starters in this business, who have a great idea, but doesn’t have adequate knowledge about the financial aspects of running a business

The module has 5 chapters, each focusing on their own topic. The work forms are magazine like text pages, videos, miniquizzes, interviews and link pages. It’s optimized for the ipad, including icons for in the home screen, splash screen and the possibility to install the app and run it in full-screen mode.

This module was made in collaboration with Tinqwise. Tinqwise is an organisation specialized in e-learning software, and they are responsible for the idea and the content.
      `,
      nl: `
“Tel uit je winst” is een e-learning module over boekhouding in de horeca sector. De primarire doelgroep zijn starters in deze branche, die een goed idee hebben, maar bijgeschoold moeten worden in de financiele aspecten van een bedrijf runnen.

De module heeft 5 hoofdstukken en elk hoofdstuk behandelt zijn eigen onderwerp. De e-learning gebruikt veel werkvormen zoals magazine tekstpagina’s, videos, miniquizzen, interviews en links naar verdere informatie online. De module is geoptimaliseerd voor de iPad: bezoekers kunnen de applicatie beleven in full-screen zoals een “native” ipad app en hem toevoegen aan hun home-screen.

Deze e-learning is gemaakt in samenwerking met Tinqwise.
      `
    },
    coverImage: "svh2/logo.jpg",
    tags: ["e-learning", "ruby", "nanoc", "scss", "javascript", "scorm"],
    smallImages: true,
    images: [
      "svh2/01-start.png",
      "svh2/02-mainmenu.png",
      "svh2/03-submenu.png",
      "svh2/04-text.png",
      "svh2/05-intro.png",
      "svh2/06-video.png",
      "svh2/07-links.png",
      "svh2/08-quiz.png",
      "svh2/09-tips.png"
    ]
  },
  {
    name: "Universiteit Wageningen - Better safe than sorry",
    color: "rgb(27,72,106)",
    // slug: "universiteit-wageningen",
    description: {
      en: `
“Better safe than sorry” is a course in basic lab security, aiming to teach newcomers to the lab environments how to behave around chemical and biological agents.

It has many different working forms, such as videos, text, quizzes, hotspots, video hotspots, a test and more.

This e-learning was built together with Tinqwise and Studio Blink. All the content was written by Tinqwise.
      `,
      nl: `
“Better safe than sorry” is een e-learning module over veiligheid in een laboritorium. De doelgroep zijn eerste-jaars studenten, die voor het eerst met chemische en biologische materialen gaan werken.

Veel verschillende werkvormen worden gebruikt: videos, text, quiz-vragen, hotspots, video’s met ingebouwde hotspots, een test en meer. Hij integreert met SCORM compliant LMS software.

Deze e-learning was gemaakt samen met Tinqwise en Studio Blink. Alle inhoud is geschreven door Tinqwise.
      `
    },
    coverImage: "wur/logo.gif",
    tags: ["e-learning", "ruby", "nanoc", "scss", "javascript", "scorm"],
    images: [
      "wur/test.png",
      "wur/01-start.png",
      "wur/02-menu.png",
      "wur/03-video-hotspot.png",
      "wur/04-video-hotspot2.png",
      "wur/05-ten-golden-rules.png",
      "wur/06-hotspot.png",
      "wur/07-lab1.png",
      "wur/09-popup.png",
      "wur/10-video-popup.png",
      "wur/11-quiz.png",
      "wur/12-video.png",
      "wur/13-video2.png"
    ]
  },
  {
    name: "Performr",
    color: "rgb(96,115,114)",
    // slug: "performr",
    description: {
      en: `
Performr is a company specialized in software testing. This is their temporary website announcing the upcoming new website.

The site is a sideways scroller, and makes use of Scroll-o-rama to enable timeline based animation based on the scroll position.
    `,
      nl: `
Performr is een bedrijf dat software test. Deze site was hun tijdelijke infopagina, terwijl ze aan de slag gingen met hun huidige website.

De site is een one-pager en gebruikt animaties en verticale scrolling om de gebruiker door het verhaal te leiden.
    `
    },
    coverImage: "performr/logo.png",
    categories: ["Website", "Campaign"],
    smallImages: true,
    images: [
      "performr/screen1.png",
      "performr/screen2.png",
      "performr/screen3.png",
      "performr/screen4.png"
    ]
  },
  {
    name: "Nederland schoon",
    color: "rgb(116,200,67)",
    // slug: "nederland-schoon",
    description: {
      en: `
“Nederland schoon” is an organisation that is trying to tackle the problem of street-litter in Holland. They launch campaigns, commence investigation and offer incentives. They also design a very famous trash can.

This project is a small quiz divided in two parts, one form managers, and one for employees. The content for the managers is informed and offers feedback, and at the end you can register as a supporter. The employees are pit against a timer, and can win a prize if they get it all right.
      `,
      nl: `
NederlandSchoon wil zwerfafval in Nederland voorkomen en bestrijden. Zij voeren campagnes tegen zwerfafval, doen onderzoek, adviseren bedrijven en zetten acties op.

Dit project is een kleine quiz bestaande uit twee delen: een voor managers en een voor werknemers. Het gedeelte voor managers is informatief en geeft feedback en aan het eind kun je je als supporter registreren. De werknemers krijgen een vragenlijst. Als ze de vragenlijst binnen een tijdlimiet goed hebben beantwoord kunnen zij een prijs winnen.
      `
    },
    coverImage: "nederland-schoon/logo.jpg",
    categories: ["Campaign"],
    smallImages: true,
    images: [
      "nederland-schoon/01-start.png",
      "nederland-schoon/02-rules.png",
      "nederland-schoon/03-checkbox.png",
      "nederland-schoon/04-radio.png",
      "nederland-schoon/05-checkbox-feedback.png",
      "nederland-schoon/06-slider.png",
      "nederland-schoon/07-rules-popup.png",
      "nederland-schoon/08-form.png"
    ]
  },
  {
    name: "Bedrijvenkring Schieoevers",
    color: "rgb(135,33,38)",
    // slug: "bks",
    description: {
      en: `
Bedrijvenkring Schieoevers is a organisation of companies in Delft. They organise events, shop collectively and promote sustainable growth in the Delft area. Their cornerstone is the belief that with more people you are stronger.

The site was built in PHP and MySQL using an in house CMS system. The site was developed with my former colleagues at CODE Internet Applications.
      `,
      nl: `
“Samen sta je sterker” is van toepassing bij Bedrijvenkring Schieoevers. Het is een coöperatie van bedrijven in Delft. Zij organiseren events, kopen groter in en zorgen voor duurzame groei in Delft en omgeving.

Deze site is gebouwd in PHP en MySQL en een in-house CMS systeem. Ik heb deze site gebouwd toen ik werkte bij Code Internet Applications in Delft.
      `
    },
    coverImage: "bks/logo.png",
    categories: ["Website"],
    images: [
      "bks/screenshot-1.png",
      "bks/screenshot-2.png",
      "bks/screenshot-3.png",
      "bks/screenshot-4.png",
      "bks/screenshot-5.png",
      "bks/screenshot-6.png",
      "bks/screenshot-7.png",
      "bks/screenshot-8.png",
      "bks/screenshot-9.png",
      "bks/screenshot-10.png"
    ]
  },
  {
    name: "ARHKitecture",
    color: "rgb(125,65,30)",
    // slug: "arhkitecture",
    description: {
      en: `
Arhkitecture is a young architect firm based in Amsterdam in the Netherlands. Their primary business is designing medical institutions, doing villa overhauls, and reselling design furniture. They have a very impressive portfolio.

The site contains the latest news and an extensive product catalog and portfolio for ARHK. It also has information on how to get in touch with them, and you can read the latest news.

The site was built in PHP and MySQL using a proprietary cms solution. The site was developed with my former colleagues at CODE Internet Applications.
      `,
      nl: `
Arhkitecture is een architectenbureau in Amsterdam. Hun primaire bezigheden bestaat uit het ontwerpen van gebouwen van medische klinieken, het renoveren van villa’s en het verkopen van designmeubels. Ze hebben een indrukwekkende portfolio.

Op de site van Arhitecture kun je het laatste nieuws lezen en door hun uitgebreide productcatalogus bladeren. Je kunt ook contact met hun opnemen via de site.

De website is geboud in PHP en MySQL, gebaseerd op een in-house CMS oplossing. Ik heb deze site gebouwd toen ik werkte bij Code Internet Applications in Delft.
      `
    },
    coverImage: "arhk/logo.gif",
    images: [
      "arhk/01-home.png",
      "arhk/02-textpage1.png",
      "arhk/03-gallery1.png",
      "arhk/04-gallery2.png",
      "arhk/05-gallery3.png",
      "arhk/06-contact.png"
    ],
    categories: ["Website"]
  },
  {
    name: "Croon electrotechniek",
    // slug: "croon",
    // color: "rgb(88,39,97)",
    color: "rgb(185,210,96)",
    description: {
      en: `
Croon electrotechniek is one of the cornerstones of Dutch industry. They exists for more than 135 years. They are specialized in Industry, Marine electronics and infrastructure. This site contains information about croon and their history, news items, a search fuctionality and much more.

The site was built in PHP and MySQL using a proprietary cms solution. The site was developed with my former colleagues at CODE Internet Applications.
      `,
      nl: `
Croon electrotechniek is een van de oudeste bedrijven in de Nederlandse industrie. Ze bestaan meer dan 135 jaar. Ze zijn gespecialiseerd in Industrie, Marine electronica en infrastructuur. Op de site kan de bezoeker lezen over Croon en hun historie, nieuws items bekijken en zoeken binnen de website.

De site was gebouwd met PHP en MySQL met een in-house CMS framework. Ik heb de website gebouwd toen ik werkte bij Code Internet Applications in Delft.
      `
    },
    coverImage: "croon/logo.svg",
    categories: ["Website"],
    images: [
      "croon/01-homepage.png",
      "croon/02-textpage.png",
      "croon/03-textpage-with-download.png",
      "croon/04-news.png",
      "croon/05-search.png"
    ]
  },
  {
    name: "European Urology",
    // slug: "european-urology",
    color: "#eeeeee",
    description: {
      en: `
European urology is a magazine for urologists in Europe. The site contains many publications from this organisation, and is actually parsed from a propritary XML format for publications into HTML. The site is quite acedemic in nature, but with a humerous twist as well. It contains many videos and images, as long with features for generating slides on the website and downloading them as a powerpoint.

The site was built in PHP and MySQL using a proprietary cms solution. The site was developed with my former colleagues at CODE Internet Applications.
      `,
      nl: `
European urology is een magazine voor uroloogen in Europa. De website is een online platform voor de vele publicaties van deze organisatie. De inhoud wordt opgemaakt in HTML vanuit XML in een formaat van Elsevier. Het is een acedemische site, maar heeft ook een vleugje humor. Video’s en afbeeldingen maken een groot deel uit het inhoud. De site biedt een mogelijkheid om een slideshoe samen te stellen van assets gevonden op de site en om deze te exporteren als een PowerPoint bestand.

Aan deze site heb ik gebouwd toon ik werkte bij Code Internet Applications in Delft. Bij het werken hieraan gebruikte ik PHP en MySQL en een in house CMS framework.
      `
    },
    coverImage: "european-urology/logo.png",
    categories: ["Website"],
    images: [
      "european-urology/01-home.png",
      "european-urology/02-current.png",
      "european-urology/03-news.png",
      "european-urology/04-press.png",
      "european-urology/05-slides.png",
      "european-urology/06-archive.png"
    ]
  },
  {
    name: "Natuurwerkdag",
    // slug: "natuurwerkdag",
    color: "rgb(183,189,78)",
    description: {
      en: `
Natuurwerkdag is an initiative to give nature a “helping hand” once in a while. It happens on many different locations in Holland. Volunteers get together and clean up, restore and beautify locations in nature, that have been neglected for too long. On this website, you can subscribe to locations, read news, and get more information.

The site was built in PHP and MySQL using a proprietary cms solution. The site was developed with my former colleagues at CODE Internet Applications.
      `,
      nl: `
Natuurwerkdag is een initiatief om de natuur een helpende hand te geven. Het gebeurt een keer per jaar op vele verschillende locaties in Nederland. Vrijwilligers komen samen voor schoonmaak en restauratie van de locaties in de natuur die te lang geen aandacht hebben gekregen. Op deze website kun je je inscrijven op locaties als vrijwilliger, nieuws lezen en informatie opzoeken.

De site is gebouwd in PHP en MySQL en een in-house CMS oplossing. Gemaakt met collega’s van Code Internet Applications toen ik daar werkte.
      `
    },
    coverImage: "natuurwerkdag/logo.jpg",
    categories: ["Website"],
    images: [
      "natuurwerkdag/01-home.png",
      "natuurwerkdag/02-search.png",
      "natuurwerkdag/02-textpage.png",
      "natuurwerkdag/03-location-detail.png",
      "natuurwerkdag/03-profiles.png",
      "natuurwerkdag/04-archive.png",
      "natuurwerkdag/05-atoz.png",
      "natuurwerkdag/06-about.png",
      "natuurwerkdag/07-press.png",
      "natuurwerkdag/08-textpage2.png",
      "natuurwerkdag/09-photos.png",
      "natuurwerkdag/10-lightbox.png",
      "natuurwerkdag/11-contact.png",
      "natuurwerkdag/12-photo2.png"
    ]
  },
  {
    name: "Not your daughter’s jeans",
    // slug: "nydj",
    color: "rgb(43,34,82)",
    description: {
      en: `
NYDJ (Not Your Daughter’s Jeans) is a retailer of of clothes for women. Their innovative lift/tuck technology is their trademark. This site contains their catalogue as well as information about the technology. It also has a store locator, and a contact page.

The site was built in PHP and MySQL using a proprietary cms solution. The site was developed with my former colleagues at CODE Internet Applications.
      `,
      nl: `
NYDJ (Not Your Daughter’s Jeans) is een label voor dameskleding. Zij hebben een innovatieve lift/tuck technologie gepatenteerd. Deze site is hun online catalogus en verstrekt informatie over deze technologie. Op de website is ook een store locator ingebouwd en een contactformulier om contact met NYDJ op te nemen.

Deze site is gebouwd is PHP en MySQL, in samenwerking met mijn oude collega’s bij Code Internet Applications in Delft.
      `
    },
    coverImage: "nydj/logo.jpg",
    categories: ["Website"],
    images: [
      "nydj/01-home.png",
      "nydj/02-about.png",
      "nydj/03-collection.png",
      "nydj/04-fitguide.png",
      "nydj/05-map.png",
      "nydj/06-contact.png"
    ]
  },
  {
    name: "Panta Rhei",
    color: "rgb(189,54,102)",
    // slug: "panta-rhei",
    description: {
      en: `
Panta Rhei is an association of basic schools in the Hague area. For this customer, a commercial website was made, and a website for each and every member of the organisation, each with it’s own theming. The school websites have an agenda, a blogging system for all groups, informative pages, photo albums and more. The commercial site of has a school map locator, and serves as a central information point for all the schools. It has a nice and friendly design.

The site was built in PHP and MySQL using a proprietary cms solution. The site was developed with my former colleagues at CODE Internet Applications.
      `,
      nl: `
Panta Rhei is een stichting en het bevoegd gezag van 15 scholen voor primair onderwijs in de gemeente Leidschendam-Voorburg, Voorschoten en Den Haag.

De opdracht was om een website te bouwen voor de organsatie van Panta Rhei en subsites voor alle 15 scholen die daarbij aangesloten zijn. Alle sites gebruiken dezelfde codebase. Elke subsite heeft een eigen thema. De scholen websites hebben een agenda, textpagina’s, een blog en photo albums. De site van de organisatie heeft nieuwspagina’s, tekstpagina’s en een tool om de scholen op te zoeken op een kaart.
      `
    },
    coverImage: "panta-rhei/logo.jpg",
    categories: ["Website"],
    smallImages: true,
    images: [
      "panta-rhei/photo1.png",
      "panta-rhei/photo2.png",
      "panta-rhei/photo3.png",
      "panta-rhei/photo4.png",
      "panta-rhei/photo5.png",
      "panta-rhei/photo6.png",
      "panta-rhei/photo7.png",
      "panta-rhei/photo8.png"
    ]
  },
  {
    name: "Koninklijke Luchtmacht",
    color: "rgb(13,25,43)",
    // slug: "koninklijke-luchtmacht",
    description: {
      en: `
This is the vacancy/recruitment site for the Royal Dutch Air Force. On this site you can find the relevant information if you are looking for a career as a pilot or anything related to the air force. The site is young-spirited and educational in nature. It has a very good looking design.

The site was built in PHP and MySQL using a proprietary cms solution. The site was developed with my former colleagues at CODE Internet Applications.
      `,
      nl: `
De recuitment site van de Koninklijke Luchtmacht. Op deze site kunnen bezoekers de relevante informatie over het werken bij de luchtmacht opzoeken. De doelgroep zijn jonge mensen die een carrière wensen als piloot of support bij de luchtmacht. De sfeer is jong, educatief een fris, met een goed design.

De site was gebouwd met PHP en MySQL en een in-house CMS framework. Ik heb hieraan gewerkt bij CODE Internet Applications in Delft.
      `
    },
    coverImage: "wbdl/logo.jpg",
    categories: ["Website"],
    images: [
      "wbdl/01-home.png",
      "wbdl/02-page1.png",
      "wbdl/03-page2.png",
      "wbdl/04-agenda.png",
      "wbdl/05-jobs.png"
    ]
  },
  {
    name: "PIT Training",
    color: "rgb(164,204,205)",
    description: {
      en: `
Pit Training is a young training agency, who mainly focuses on training youngsters in social skills and communication, through a methodology called “rock and water”.

The agency is a union of two freelancers, who combine their strengths and experience in the field.

The website is a scrollable one-pager and offers a nice layout with some subtle animations and responsiveness.
      `,
      nl: `
Pit training is een trainingsbureau. Hun primaire focus is het trainen van jonge mensen in social skills en communicatie, door het toepassen van de methode van “Rots en Water”.

Het bureau is het resultaat van een samenwerking tussen twee freelancers, die met hun ervaring en kennis een sterk product neerzetten.

De website is een one-pager met subtiele animaties. De layout is responsive een schaalt naar alle platforms.
      `
    },
    coverImage: "pit/logo.png",
    categories: ["Website", "One-pager"],
    images: [
      "pit/01-desktop.png",
      "pit/02-desktop-errors.png",
      "pit/03-mobile-1.png",
      "pit/04-mobile-menu.png",
      "pit/05-mobile-2.png",
      "pit/06-mobile-3.png",
      "pit/07-mobile-4.png",
      "pit/08-mobile-errors.png"
    ]
  }
];

for (const item of portfolioItems) {
  item.slug = slugify(item.name);
}
export default portfolioItems;
