import React, { Component } from "react";
import { TorusGeometry, MeshNormalMaterial, Mesh } from "three";
import StandardSceneManager from "./StandardSceneManager";
import makeSkybox from "./skybox";
import OrbitControls from "./OrbitControls";

class Torus extends Component {
  componentDidMount() {
    this.sceneManager = new StandardSceneManager(this.refs.container);
    this.sceneManager.addControls(new OrbitControls(this.sceneManager.camera));

    const geometry = new TorusGeometry(1, 0.42);
    const material = new MeshNormalMaterial();
    const mesh = new Mesh(geometry, material);

    this.sceneManager.add(mesh);
    this.sceneManager.add(makeSkybox());

    this.update();
  }

  componentDidUpdate() {
    this.update();
  }

  update() {
    if (this.props.active) {
      this.sceneManager.start();
    } else {
      this.sceneManager.stop();
    }
  }

  componentWillUnmount() {
    this.sceneManager.destroy();
  }

  render() {
    return (
      <div
        className="torus"
        ref="container"
        style={{ height: "100vh", width: "100vw" }}
      />
    );
  }
}

export default Torus;
