import { Scene, WebGLRenderer, PerspectiveCamera } from "three";

class StandardSceneManager {
  active = false;

  constructor(domElement, controls) {
    this.domElement = domElement;

    this.renderer = new WebGLRenderer({
      antialias: true // to get smoother output
    });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.domElement.appendChild(this.renderer.domElement);

    this.scene = new Scene();

    this.camera = new PerspectiveCamera(
      45,
      window.innerWidth / window.innerHeight,
      1,
      10000
    );
    this.camera.position.set(0, 0, 5);
    this.scene.add(this.camera);

    this.draw();

    window.addEventListener("resize", this.onResize, false);
  }

  start() {
    if (!this.active) {
      this.active = true;
      this.animate();
    }
  }

  stop() {
    if (this.active) {
      cancelAnimationFrame(this.anim);
      this.active = false;
    }
  }

  addControls(controls) {
    this.controls = controls;
  }

  add(object) {
    this.scene.add(object);
  }

  destroy() {
    this.stop();
    window.removeEventListener("resize", this.onResize, false);
  }

  onAnim = callback => {
    this.onAnimCallBack = callback;
  };

  // animation loop
  animate = () => {
    if (!this.active) {
      return;
    }

    this.anim = requestAnimationFrame(this.animate);

    this.draw();

    if (this.onAnimCallBack) {
      this.onAnimCallBack(this);
    }
  };

  draw = () => {
    this.renderer.render(this.scene, this.camera);
  };

  onResize = () => {
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(window.innerWidth, window.innerHeight);
  };
}

export default StandardSceneManager;
