export default {
  name: "Resources for freelancers in the Netherlands",
  date: "2012/04/20",
  description: "A list of some resources for freelancers in the Netherlands.",
  content: `
When starting as a freelancer, there are quite a few things you have to keep in mind, regarding tax rules, making invoices etc.

This is a list of sites that i have consulted when sorting these thing out for myself. This list is very much specific to the Netherlands.

- [Kamer van Koophandel](https://www.kvk.nl)
- [Eenmanszaak oprichten](https://www.eenmanszaakoprichten.nl/)
- [We work remotely](https://weworkremotely.com/)
- [Hoofdkraan.nl](https://www.hoofdkraan.nl/)
- [Meetup.com](https://www.meetup.com/)

UPDATE: I removed some dead links and updated one link to the new address.
`,
  tags: ["freelance", "resources"],
  category: "Freelancing"
};
