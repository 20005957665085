import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "../Routes/Routes";
import CoreLayout from "../../layouts/CoreLayout";

const App = props => (
  <Router>
    <CoreLayout lang={props.lang}>
      <Routes />
    </CoreLayout>
  </Router>
);

export default App;
