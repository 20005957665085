import { ABOUT_TOGGLE_MUTED, ABOUT_TOGGLE_PAUSED } from "./constants";

const initialState = {
  muted: false,
  paused: true,
  volume: 0.5
};

const about = (state = initialState, action) => {
  switch (action.type) {
    case ABOUT_TOGGLE_MUTED:
      return { ...state, muted: !state.muted };
    case ABOUT_TOGGLE_PAUSED:
      return { ...state, paused: !state.paused };
    default:
      return state;
  }
};

export default about;
