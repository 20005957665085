import React, { Component } from "react";
import Sidebar from "../Sidebar/Sidebar";
import experiments from "./experiments/experiments";
import { Link, NavLink } from "react-router-dom";
import { l } from "../../helpers/i18n";

class Navigation extends Component {
  renderExperiment = experiment => {
    return (
      <li>
        <NavLink
          onClick={this.props.toggleMenu}
          activeClassName="active"
          className="experiment-link"
          to={`/experiments/${experiment.slug}`}
        >
          {l(experiment.name)}
        </NavLink>
      </li>
    );
  };

  renderExperiments() {
    return (
      <ul className="list-unstyled">
        {experiments.map(this.renderExperiment)}
      </ul>
    );
  }

  render() {
    return (
      <Sidebar open={this.props.open} toggleMenu={this.props.toggleMenu} left>
        <div className="experiments-navigation">
          <Link
            onClick={this.props.toggleMenu}
            to="/experiments"
            className="experiment-link"
          >
            <h3>Experiments</h3>
          </Link>
          {this.renderExperiments()}
        </div>
      </Sidebar>
    );
  }
}

export default Navigation;
