import React, { Component } from "react";
import StandardSceneManager from "./StandardSceneManager";
import { SphereGeometry, MeshNormalMaterial, Mesh } from "three";
import OrbitControls from "./OrbitControls";
import makeSkybox from "./skybox";

class Ball extends Component {
  componentDidMount() {
    this.sceneManager = new StandardSceneManager(this.refs.container);
    this.sceneManager.addControls(new OrbitControls(this.sceneManager.camera));

    const geometry = new SphereGeometry(1, 32, 32);
    const material = new MeshNormalMaterial();
    const mesh = new Mesh(geometry, material);

    this.sceneManager.add(mesh);
    this.sceneManager.add(makeSkybox());

    this.update();
  }

  componentDidUpdate() {
    this.update();
  }

  update() {
    if (this.props.active) {
      this.sceneManager.start();
    } else {
      this.sceneManager.stop();
    }
  }

  componentWillUnmount() {
    this.sceneManager.destroy();
  }

  render() {
    return (
      <div
        className="ball"
        ref="container"
        style={{ height: "100%", width: "100%" }}
      />
    );
  }
}

export default Ball;
