export default {
  name: "How to start a development server in PHP",
  date: "2013/04/12",
  description:
    "How to start a small development-only server in a local directory on your development machine.",
  content: `
Maybe the whole world knew this, and I am the only one who was really surprised when I found out that - like in Ruby in Rails - with PHP it is actually really easy to start a small development server, without having to go through configuring apache virtual domains or messing around with your hosts file.

I usually work on only one project at a time. So this is more than sufficient for me.

Go to the base directory (usually where your index.php file is located), and enter this command:

\`\`\`
php -S localhost:8000
\`\`\`

You should see this output:

\`\`\`
PHP 5.4.13 Development Server started at Fri Apr 12 14:04:01 2013
Listening on http://localhost:8000
Document root is /Users/jakob/code/jakoblaegdsmand_nanoc
Press Ctrl-C to quit.
\`\`\`

You now have a development server spinning on http://localhost:8000 and you can access it by pointing your browser there. No more apache or nginx needed for development purposes.

You have to leave the terminal tab open though, because if you kill the terminal process, you kill the server. That’s why people invented tabbed terminal windows …

Cheers!
`,
  tags: ["php", "server", "development"],
  category: "Programming"
};
