import slugify from "../../helpers/slugify";
import { l } from "../../helpers/i18n";

const categories = [
  {
    name: {
      en: "Languages",
      nl: "Talen"
    },
    description: {
      en:
        "The different programming languages i have worked with over the years",
      nl:
        "De programeertalen die ik in de loop van de jaren ervaring mee hebt opgebouwd"
    },
    color: "red",
    skills: [
      {
        name: "Ruby",
        score: 95
      },
      {
        name: "Elixir",
        score: 80
      },
      {
        name: "JavaScript",
        score: 90
      },
      {
        name: "CSS",
        score: 100
      },
      {
        name: "PHP",
        score: 85
      },
      {
        name: "Python",
        score: 50
      },
      {
        name: "Go",
        score: 60
      },
      {
        name: "Swift",
        score: 65
      },
      {
        name: "HTML",
        score: 100
      },
      {
        name: "C++",
        score: 50
      },
      {
        name: "C#",
        score: 60
      },
      {
        name: "Swift",
        score: 75
      },
      {
        name: "Objective-C",
        score: 60
      },
      {
        name: "Bash",
        score: 50
      },
      {
        name: "SQL",
        score: 80
      }
    ]
  },
  {
    name: "Frameworks",
    description: {
      en: "Different framework and libraries that I use regularly",
      nl:
        "Verschillende frameworks en libraries die ik regelmatig gebruik van maak"
    },
    color: "blue",
    skills: [
      {
        name: "Ruby on Rails",
        score: 90
      },
      {
        name: "Node.js",
        score: 80
      },
      {
        name: "Express",
        score: 80
      },
      {
        name: "Koa",
        score: 80
      },
      {
        name: "React",
        score: 85
      },
      {
        name: "Redux",
        score: 70
      },
      {
        name: "jQuery",
        score: 95
      },
      {
        name: "Angular",
        score: 80
      },
      {
        name: "Laravel",
        score: 80
      },
      {
        name: "Django",
        score: 60
      },
      {
        name: "Middleman",
        score: 90
      },
      {
        name: "Phoenix",
        score: 70
      },
      {
        name: "Nanoc",
        score: 95
      },
      {
        name: "Unity",
        score: 75
      },
      {
        name: "Three.js",
        score: 80
      },
      {
        name: "Lumen",
        score: 60
      },
      {
        name: "Sinatra",
        score: 50
      }
    ]
  },
  {
    name: {
      en: "Spoken Languages",
      nl: "Spreektalen"
    },
    description: {
      en: "Communication is important and these are the languages i speak",
      nl: "Communicatie is belangrijk en dit zijn de talen die ik beheers"
    },
    color: "purple",
    skills: [
      {
        name: {
          en: "English",
          nl: "Engels"
        },
        score: 90
      },
      {
        name: {
          en: "Dutch",
          nl: "Nederlands"
        },
        score: 80
      },
      {
        name: {
          en: "Danish",
          nl: "Deens"
        },
        score: 100
      },
      {
        name: {
          en: "Spanish",
          nl: "Spaans"
        },
        score: 50
      },
      {
        name: {
          en: "German",
          nl: "Duits"
        },
        score: 45
      }
    ]
  },
  {
    name: "Databases",
    description: {
      en:
        "Modern applications need databases to function properly when dealing with dynamic data",
      nl:
        "Moderne applicaties hebben een database nodig om goed te kunnen functioneren als de data dynamisch is"
    },
    color: "green",
    skills: [
      {
        name: "MySQL",
        score: 90
      },
      {
        name: "Postgresql",
        score: 80
      },
      {
        name: "MongoDB",
        score: 70
      },
      {
        name: "Redis",
        score: 40
      },
      {
        name: "SQLite",
        score: 70
      }
    ]
  },
  {
    name: {
      en: "Techniques",
      nl: "Technieken"
    },
    description: {
      en: "Methodologies and project techniques that I am aqquainted with",
      nl: "Tehodieken en project technieken dat ik ervaring mee heb"
    },
    color: "orangered",
    skills: [
      {
        name: "Scrum / agile",
        score: 70
      },
      {
        name: "Pair programming",
        score: 70
      }
    ]
  },
  {
    name: {
      en: "Specialties",
      nl: "Specialiteiten"
    },
    description: {
      en: "Areas where I excell as a programmer",
      nl: "Gebieden waar mijn kennis echt tot zijn recht komt"
    },
    color: "gray",
    skills: [
      {
        name: "Tablets / mobile",
        score: 70
      },
      {
        name: "SPA",
        score: 90
      },
      {
        name: "Responsive design",
        score: 80
      },
      {
        name: "Scripting / automation",
        score: 60
      },
      {
        name: "Animations",
        score: 75
      },
      {
        name: "AJAX",
        score: 80
      },
      {
        name: "TDD",
        score: 85
      },
      {
        name: "OOP",
        score: 90
      },
      {
        name: "FP",
        score: 90
      }
    ]
  },
  {
    name: {
      en: "Platforms",
      nl: "Platformen"
    },
    description: {
      en: "Platforms where I feel at home",
      nl: "Platformen waar ik me thuis voel"
    },
    color: "brown",
    skills: [
      {
        name: "HTML5",
        score: 90
      },
      {
        name: "Mac OS X",
        score: 80
      },
      {
        name: "Windows",
        score: 60
      },
      {
        name: "Linux",
        score: 80
      },
      {
        name: "iOS",
        score: 80
      },
      {
        name: "Android",
        score: 70
      }
    ]
  },
  {
    name: {
      en: "Software",
      nl: "Software"
    },
    description: {
      en: "Software I use regularly in my work",
      nl: "Software die ik regelmatig gebruik voor mijn werk"
    },
    slug: "programs",
    skills: [
      {
        name: "Photoshop"
      },
      {
        name: "Illustrator"
      },
      {
        name: "InDesign"
      },
      {
        name: "Flash"
      },
      {
        name: "Fireworks"
      },
      {
        name: "The Gimp"
      },
      {
        name: "Blender"
      },
      {
        name: "Textmate"
      },
      {
        name: "Sublime text"
      },
      {
        name: "Atom"
      },
      {
        name: "XCode"
      }
    ]
  }
];

for (const category of categories) {
  category.slug = slugify(l(category.name));
}

export default categories;
