import React, { Component } from "react";
import PropTypes from "prop-types";
// import { Link } from "react-router-dom";
import "./popup.scss";
import Helmet from "react-helmet";

let className = "popup";

class Popup extends Component {
  static propTypes = {
    children: PropTypes.object,
    close: PropTypes.func,
    nextLink: PropTypes.string,
    prevLink: PropTypes.string
  };

  next = event => {
    event.stopPropagation();
    this.props.nextLink();
  };

  prev = event => {
    event.stopPropagation();
    this.props.prevLink();
  };

  renderLinks() {
    const result = [];
    if (this.props.nextLink) {
      result.push(<button className="next" onClick={this.next} key="next" />);
    }
    if (this.props.prevLink) {
      result.push(<button className="prev" onClick={this.prev} key="prev" />);
    }
    return result;
  }

  render() {
    return (
      <div className={className} onClick={this.props.close}>
        <Helmet>
          <body className="no-scroll" />
        </Helmet>
        <div className="popup-content" onClick={this.preventClick}>
          {this.props.children}
        </div>
        <button className="popup-close" />
        {this.renderLinks()}
      </div>
    );
  }
}

export default Popup;
