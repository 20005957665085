import React, { Component } from "react";
import Color from "color";
import ReactMarkdown from "react-markdown";
// import { Transition, config } from "react-spring";
import { l } from "../../helpers/i18n";
import Popup from "../Popup/Popup";
import classNames from "classnames";
// import { preloadImages } from "../../helpers/preload";

const SLIDESHOW_INTERVAL = 5000;

class PortfolioItem extends Component {
  slideshowInterval = null;
  state = {
    imageIndex: 0,
    slideshowInterval: null,
    popupActive: false
  };

  // componentWillMount() {}

  hasImages = () => this.props.item.images && this.props.item.images.length;

  renderImage = image => {
    return (
      <img
        className="image"
        src={image}
        alt={image}
        onClick={this.openPopup}
        key={this.state.imageIndex}
      />
    );
  };

  renderImages() {
    const hasImages = this.hasImages();
    if (!hasImages) return null;
    const imageUrl = this.props.item.images[this.state.imageIndex];
    const image = require(`../../static/images/portfolio/${imageUrl}`);
    if (!image) return null;
    const className = classNames("images", {
      small: this.props.item.smallImages
    });
    return (
      <div
        className={className}
        onMouseOver={this.stopSlideshow}
        onMouseOut={this.startSlideshow}
      >
        <button className="next-button" onClick={this.nextImage} />
        <button className="prev-button" onClick={this.prevImage} />
        {this.renderImage(image)}
      </div>
    );
  }

  componentDidMount() {
    // preloadImages(this.props.item.images);
    this.updateSlideshow();
  }

  componentDidUpdate(oldProps) {
    if (oldProps.active !== this.props.active) {
      this.updateSlideshow();
    }
  }

  updateSlideshow() {
    if (!this.hasImages()) return;
    if (this.props.active) {
      this.startSlideshow();
    } else {
      this.stopSlideshow();
    }
  }

  startSlideshow = () => {
    if (!this.state.slideshowInterval) {
      const slideshowInterval = setInterval(this.nextImage, SLIDESHOW_INTERVAL);
      this.setState({ slideshowInterval });
    }
  };

  stopSlideshow = () => {
    if (this.state.slideshowInterval) {
      clearInterval(this.state.slideshowInterval);
      this.setState({ slideshowInterval: null });
    }
  };

  nextImage = () => {
    const count = this.props.item.images.length;
    this.setState({
      imageIndex: (((this.state.imageIndex + 1) % count) + count) % count
    });
  };

  prevImage = () => {
    const count = this.props.item.images.length;
    this.setState({
      imageIndex: (((this.state.imageIndex - 1) % count) + count) % count
    });
  };

  renderTag = tag => {
    const className = classNames(
      "tag",
      Color(this.props.item.color).isLight() ? "black" : "white"
    );

    const style = {
      color: this.props.item.color
    };

    return (
      <span className={className} style={style}>
        <span className="hole">&bull;</span>
        {tag}
      </span>
    );
  };

  renderTags() {
    if (!this.props.item.tags) return null;
    return (
      <div className="tags">{this.props.item.tags.map(this.renderTag)}</div>
    );
  }

  renderText() {
    return (
      <div className="text">
        <div className="text-content">
          <ReactMarkdown source={l(this.props.item.description)} />
          {this.renderTags()}
        </div>
      </div>
    );
  }

  openPopup = () => {
    this.stopSlideshow();
    this.setState({ popupActive: true });
  };

  closePopup = () => {
    this.setState({ popupActive: false });
    this.startSlideshow();
  };

  renderPopup() {
    if (!this.state.popupActive) return null;
    const hasImages = this.hasImages();
    if (!hasImages) return null;
    const imageUrl = this.props.item.images[this.state.imageIndex];
    const image = require(`../../static/images/portfolio/${imageUrl}`);
    return (
      <Popup
        close={this.closePopup}
        nextLink={this.nextImage}
        prevLink={this.prevImage}
      >
        <img
          className="image"
          src={image}
          key={this.state.imageIndex}
          alt={imageUrl}
        />
      </Popup>
    );
  }

  render() {
    const color = Color(this.props.item.color).isLight() ? "black" : "white";
    const style = { color };
    const coverImage = require(`../../static/images/portfolio/${
      this.props.item.coverImage
    }`);
    return (
      <div id={this.props.item.slug} className="portfolio-item" style={style}>
        <div className="title-box">
          <img src={coverImage} className="cover-image" alt="cover" />
          <h2 className="item-title">{this.props.item.name}</h2>
        </div>
        <div className="content">
          {this.renderImages()}
          {this.renderText()}
          {this.renderPopup()}
        </div>
      </div>
    );
  }
}

export default PortfolioItem;
