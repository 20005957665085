export default {
  name: "A better terminal on windows 7",
  date: "2013/09/23",
  description: "How to get a fairly decent terminal experience on windows 7.",
  content: `
The windows “command-line” application is a bit of a joke! It’s almost insulting to use this as a programmer when you’ve been used to using bash or zsh on mac or unix/linux based systems, maybe even pimped up with “oh-my-zsh”, and using iTerm2, if you are on a mac. Then you really feel in control. SIGH. But we are on windows …

If you insist on using Windows though, there is a way to get a remotely better terminal experience.

First of all download Console2 from [here](https://www.hanselman.com/blog/Console2ABetterWindowsCommandPrompt.aspx). It is a small program, it’s free, and it offers major improvements to the windows terminal program cmd.exe. First of all you can change the size of the terminal screen (DUH!). You can also open more tabs. But this terminal has a major drawback: It doesn’t support color output.

I cannot stress enough how important color output is to me in my daily chores. When you look at he output of command-line based programs the whole day, you really need colors! It may sound stupid, but colors enable you to quickly scan the whole page to see what portions are of interest to you. No need to search or read all the text. Let your visual brain parts do the work. And you are not stuck with that awful font that they use in cmd.exe.

The way to do this is to wrap your terminal program in another program called ansicon. Download it from here. Unzip this package and copy the contents from either the x86 or x64 folder, depending on which archtecture you use, to a easily accessible folder on your drive, for example C:\\ansicon.

Inside this folder you find the ansicon.exe executable. So you start ansicon.exe, and pass it as parameter the path to your console program (in our case Console2).

Let’s say you installed Console2 under C:\\Console2 and ansicon under C:\\ansicon. The command (which you could put in a .bat file) would then be:

\`\`\`
start C:\\ansicon\\ansicon.exe “C:\\Console2\\Console.exe”
\`\`\`

Your terminal now supports color. Phew!

### Alternatives

You could use Cygwin as well. This gives you a linux style terminal in windows. The only drawback is that you very easily get a stale system, as you only can reinstall and upgrade from the installer. For most people, this will not be a problem.
`,
  tags: ["windows", "terminal", "command-line"],
  category: "Setup"
};
