import React from "react";
import Header from "../../components/Header";
import "./CoreLayout.scss";
import "../../styles/core.scss";
import Helmet from "react-helmet";
import { getLocale } from "../../helpers/locale";

const lang = getLocale();

const CoreLayout = props => {
  const helmetProps = {
    titleTemplate: "Jakob Lægdsmand - %s",
    defaultTitle: "Jakob Lægdsmand"
  };
  return (
    <div className="main">
      <Helmet {...helmetProps}>
        <html lang={lang} />
        <meta
          name="description"
          content="Jakob Lægdsmand is a freelance web developer"
        />
        <meta property="og:type" content="article" />
      </Helmet>
      <div className="main-content">{props.children}</div>
      <Header />
    </div>
  );
};

export default CoreLayout;
