import React from "react";
import { Switch, Route } from "react-router-dom";

import Home from "../Home/Home";
import Portfolio from "../Portfolio/Portfolio";
import Blog from "../Blog/Blog";
import Skills from "../Skills/Skills";
import NotFound from "../NotFound/NotFound";
import Contact from "../Contact/Contact";
import About from "../About/About";
import Experiments from "../Experiments/Experiments";
import "./routes.scss";

const Routes = props => (
  <Switch>
    <Route path="/(about|over-mij)" component={About} />
    <Route path="/portfolio/:slug?" component={Portfolio} />
    <Route path="/(skills|ervaring)/:slug?" component={Skills} />
    <Route path="/blog/:slug?" component={Blog} />
    <Route path="/(experiments|experimenten)/:slug?" component={Experiments} />
    <Route path="/contact" component={Contact} />
    <Route path="/" exact component={Home} />
    <Route component={NotFound} />
  </Switch>
);

export default Routes;
