import en from "./locales/en";
import nl from "./locales/nl";
import { merge, get } from "lodash";

export const AVAILABLE_LOCALES = ["en", "nl"];
let translations = { en, nl };
const subdomain = window.location.hostname.split(".")[0];
export const lang = AVAILABLE_LOCALES.includes(subdomain) ? subdomain : "en";
export const addTranslations = data => {
  merge(translations, data);
};

export const t = label => get(translations[lang], label) || label;
export const l = (content, locale = lang) => {
  return content[locale] || content;
};
