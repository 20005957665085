export default {
  name: "Clearing dns cache on os x",
  date: "2012/05/05",
  description:
    "You can use crontab expression in your crontab file. It makes the syntax a little more readable and more agile.",
  content: `
Your computer caches DNS lookups for a short period of time. If you added a hostname to your hosts file (can be handy when testing websites locally that depend on the domain name), or changed DNS records at your ISP, you need to clear the cache to be able to see those changes.

On a Mac OS X system it is done as follows

Open the “Terminal” app. Issue this command:

\`\`\`bash
dscacheutil -flushcache
\`\`\`

On older Mac OS X systems you need to use this command:

\`\`\`bash
lookupd -flushcache
\`\`\`
`,
  tags: ["mac", "os x", "dns"],
  category: "Setup"
};
