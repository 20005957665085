import "prismjs/components/prism-ruby";

export default {
  name: "How to get an awesome looking terminal on mac os x",
  date: "2013/04/12",
  description:
    "How to get a sexy slick terminal that will please you when you are spending a lot of hours in the terminal",
  content: `
Here is a little lifehack that I wanted to share with you guys. I spend a lot of time in ther Terminal, and the terminal that comes with the development platform i use - mac os x - is robust and works fine, but it is pretty basic.

Here I present you with a few tips to spice up your terminal life:

### Step 1: Install zsh
zsh is a highly scriptable programmers shell. In it’s basic form, it is not better than bash, but if you start using the scripting functionalities, and tap into the extra resources, you can make an awesome shell with autocompletion and a very pleasing interface.

zsh is already installed on a mac, but if you want a newer version, it can be installed via homebrew:

\`\`\`bash
brew install zsh
\`\`\`

if you don’t have homebrew already, you should really get it. It is a package manager for os x and it rocks. Do a google search on homebrew to get more innformation on that.

You need to tell Terminal.app to use zsh instead of bash, this is done in Preferences > Startup > Shell opens with: and here you should select “Command (complete path)”, and type in /bin/zsh if you are using the system zsh, or /usr/local/bin/zsh if you are using the homebrew version.

### Step 2: Install "oh-my-zsh"

Oh my zsh is a collection of scripts that extend the basic functionality of zsh. I am not going to go through all the features that this collection of scripts add to zsh, and it is also a joy to experiment with it yourself. So I encourage you to try it out for yourself.

Some of the highlights are:

* Command completion
* Inline help
* Search and browse in your history
* Git integration
* Homebrew integration
* … etc. etc.

Enter this lovely one-liner in your terminal, and it will install itself. You have to enter you administrator password at some point in the installation. This is fine.

\`\`\`bash
curl -L https://github.com/robbyrussell/oh-my-zsh/raw/master/tools/install.sh | sh
\`\`\`

edit your new .zshrc file your home directory. You can select themes and plugins for usage in zsh. For a complete list of themes and plugins, look in the .oh-my-zsh folder in your home directory. This was installed with zsh. There is a themes folder here and a plugins folder.

To add new plugins, find this line in .zshrc:
\`\`\`bash
plugins=(git)
\`\`\`

I like plugins ;), so this is my setup:

\`\`\`bash
plugins=(git ruby golang django scala gem history history-substring-search terminalapp brew nanoc)
\`\`\`

One plugin that you cannot miss is the history and history-substring-search plugin. When you enter a partial command, you can search through your history (e.g. your last tar command or ssh command, which can be pretty handy) for matches of this by using the arrow-up and arrow-down keys. Awesome!!!

Look for more plugins in the plugins folder. The name of the plugin is the same as the subfolder in plugins. If you pop open the source code for the plugins you can very often see in the documentation, what commands the plugin adds to the shell.

One thing to be aware of is that some plugins can slow down the start-up time of the terminal. Play around with it, and try to disable some plugins if you are seeing drops in speed

You can also change the theme if you want:

\`\`\`bash
ZSH_THEME="bobbyrussell"
\`\`\`

Look for theme names in the themes folder. It is the file name, minus the zsh-theme extension. I am using one called “eastwood”.

### Step 3: Install solarized color theme

In the Terminal.app you have a few options of color schemes that you can choose from. We are going to install the Solarized Dark and Solarized Light themes for some human eye friendly color combinations

* Go to http://ethanschoonover.com/solarized in your browser
* Download the the latest version http://ethanschoonover.com/solarized/files/solarized.zip.
* Unzip the package and locate the osx-terminal.app-colors-solarized folder within the extracted folder.
* Double click on Solarized Dark ansi.terminal and Solarized Light ansi.terminal.
* If your system then complains that it is from an unidentified developer, you have to temporarily allow applications from anywhere. To do this go to: System Preferences > Security & Privacy > General > Allow applications downloaded from and select “Anywhere”. Don’t remember to change this back!

Select the theme in the Terminal.app preferences under Settings > Profiles. You can press default to have it default selected.

### An image

![terminal](/images/blog/terminal-eastwood.png)

### By the way
You should be able to do this on a linux machine as well.

Installing zsh is then a matter of installing via your package manager:

\`\`\`bash
sudo apt-get install zsh
\`\`\`

for example, on Debian. Look it up in your documentation.

Changing the preferences of Terminal.app of course doesn’t apply. It is possible to set the terminal colors, but that I won’t be covering here, look on the solarized page on how to do that. If you are using Gnome Terminal it is possible that you can set it in the preferences somewhere. Again look at the solarized page (see above).

To change the standard shell, you can run the little command-line utility chsh. It will prompt you to enter which command you would like to use as standard shell. Enter /bin/zsh
`,
  tags: ["terminal", "command-line", "zsh", "mac os x", "slick"],
  category: "Setup"
};
