import "prismjs/components/prism-scss";

export default {
  name: "Rouge railscasts theme (css and scss)",
  date: "2015/06/30",
  description:
    "A thumbnailer using the dragonfly gem that caches the generated the generated thumbnails instead of regenerating them every time. Can save you a lot of time.",
  content: `
Hello everyone.

For the few lucky souls looking for this on zee interwebs: Here is the railscasts theme converted to css and scss for the rouge syntax highlighter. The Rouge syntax highlighter outputs html formatted in “pygments”-style.

The railscasts editor scheme is a very calm good-looking text editor color scheme made by Ryan Bates. I am using the below styles for coloring the code examples in my blog.

SCSS version:
\`\`\`scss
$railscasts-color                         : #E6E1DC;
$railscasts-bg-color                      : #232323;

$railscasts-gutter-color                  : #858179;
$railscasts-gutter-bg-color               : #45433E;
$railscasts-gutter-divider-color          : #75715E;

$railscasts-comment-color                 : #BC9458;
$railscasts-comment-font-style            : italic;

$railscasts-keyword-color                 : #CC7833;
$railscasts-function-color                : #FFC66D;
$railscasts-class-color                   : #FFFFFF;
$railscasts-numeric-color                 : #A5C261;
$railscasts-variable-color                : #D0D0FF;
$railscasts-constant-color                : #6D9CBE;
$railscasts-constant-other-variable-color : #DA4939;
$railscasts-constant-builtin-color        : #6E9CBE;
$railscasts-constant-color                : #6D9CBE;
$railscasts-string-color                  : #A5C261;
$railscasts-library-function-color        : #DA4939;
$railscasts-library-type-color            : #6E9CBE;
$railscasts-library-constant-color        : #A5C261;
$railscasts-markup-tag-color              : #E8BF6A;
$railscasts-string-interpolation-color    : #519F50;

$railscasts-diff-add-color                : #E6E1DC;
$railscasts-diff-add-bg-color             : #144212;

$railscasts-diff-remove-color             : #E6E1DC;
$railscasts-diff-remove-bg-color          : #660000;

$railscasts-diff-header                   : #2F33AB;

pre {
  position: relative;
  background-color: #232323;
  margin-bottom: 20px;
  overflow-x: scroll;
  font-size: 13px;
  color: $railscasts-color;
  border: none;
  code {
    word-wrap: normal;
    white-space: pre;
    overflow-x: auto;
  }

  .gd {
    color: $railscasts-diff-remove-color;
    background-color: $railscasts-diff-remove-bg-color;
  }
  .gi {
    color: $railscasts-diff-add-color;
    background-color: $railscasts-diff-add-bg-color;
  }
  .c, .c1, .c2 {
    color: $railscasts-comment-color;
    font-style: $railscasts-comment-font-style;
  }
  .s1, .s2, .s, .sr {
    color: $railscasts-string-color;
  }
  .nv {
    color: $railscasts-variable-color;
  }
  .k, .o, .kp {
    color: $railscasts-keyword-color;
  }
  .mi {
    color: $railscasts-numeric-color;
  }
  .ss {
    color: $railscasts-library-type-color;
  }
  .si {
    color: $railscasts-string-interpolation-color;
  }
  .vi {
    color: $railscasts-variable-color;
  }
  .p {
    color: $railscasts-keyword-color;
  }
  .m {
    color: $railscasts-numeric-color
  }
  .nb {
    color: $railscasts-string-color;
  }
  .mh {
    color: $railscasts-variable-color;
  }
  .nl {
    color: $railscasts-library-type-color;
  }
  .nt, .nc, .na {
    color: $railscasts-function-color;
  }
}
\`\`\`

CSS version:

\`\`\`css
pre {
  position: relative;
  background-color: #232323;
  margin-bottom: 20px;
  overflow-x: scroll;
  font-size: 13px;
  color: #E6E1DC;
  border: none;
}
pre code {
  word-wrap: normal;
  white-space: pre;
  overflow-x: auto;
}
pre .gd {
  color: #E6E1DC;
  background-color: #660000;
}
pre .gi {
  color: #E6E1DC;
  background-color: #144212;
}
pre .c, pre .c1, pre .c2 {
  color: #BC9458;
  font-style: italic;
}
pre .s1, pre .s2, pre .s, pre .sr {
  color: #A5C261;
}
pre .nv {
  color: #D0D0FF;
}
pre .k, pre .o, pre .kp {
  color: #CC7833;
}
pre .mi {
  color: #A5C261;
}
pre .ss {
  color: #6E9CBE;
}
pre .si {
  color: #519F50;
}
pre .vi {
  color: #D0D0FF;
}
pre .p {
  color: #CC7833;
}
pre .m {
  color: #A5C261;
}
pre .nb {
  color: #A5C261;
}
pre .mh {
  color: #D0D0FF;
}
pre .nl {
  color: #6E9CBE;
}
pre .nt, pre .nc, pre .na {
  color: #FFC66D;
}
\`\`\`
`,
  tags: ["scss", "css", "theme", "source"],
  category: "Programming"
};
