import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import { TweenLite } from "gsap";
import Helmet from "react-helmet";
import Prism from "prismjs";

const CodeBlock = props => {
  const lang = Prism.languages[props.language];
  if (lang) {
    const html = Prism.highlight(props.value, lang);
    const cls = "language-" + props.language;

    return (
      <pre className={cls}>
        <code dangerouslySetInnerHTML={{ __html: html }} className={cls} />
      </pre>
    );
  } else {
    return (
      <pre>
        <code>{props.value}</code>
      </pre>
    );
  }
};

class BlogItemDetail extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired
  };

  componentWillLeave(callback) {
    TweenLite.to(this.refs.container, 0.5, { height: 0, onComplete: callback });
  }
  componentWillEnter(callback) {
    this.refs.container.style.height = 0;
    const height = this.refs.content.offsetHeight + 40;
    TweenLite.to(this.refs.container, 0.5, { height, onComplete: callback });
  }

  render() {
    const item = this.props.item;
    const helmetProps = {
      title: item.name,
      meta: [{ name: "description", contents: item.description }]
    };
    return (
      <div className="blog-item-detail" ref="container">
        <Helmet {...helmetProps} />
        <div className="content" ref="content">
          <ReactMarkdown
            source={item.content}
            renderers={{ code: CodeBlock }}
          />
        </div>
      </div>
    );
  }
}

export default BlogItemDetail;
