// ------------------------------------
// Constants
// ------------------------------------
export const LOCATION_CHANGE = 'LOCATION_CHANGE'

// ------------------------------------
// Actions
// ------------------------------------
export function locationChange (location = '/') {
  return {
    type    : LOCATION_CHANGE,
    payload : location
  }
}

// ------------------------------------
// Specialized Action Creator
// ------------------------------------
export const updateLocation = ({ dispatch }) => {
  return (nextLocation) => locationChange(nextLocation)
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = null
export default (state = initialState, action) => {
  return action.type === LOCATION_CHANGE ? action.payload : state
}
