const AVAILABLE_LOCALES = ["en", "nl"];

export const getLocale = () => {
  const lang = window.location.host.split(".")[0];
  if (AVAILABLE_LOCALES.includes(lang)) {
    return lang;
  } else {
    return "en";
  }
};

export const getAvailableLocales = () => AVAILABLE_LOCALES;
