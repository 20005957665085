// import "prismjs/components/prism-html";
// import "prismjs/components/prism-css";

export default {
  name: "Use css shapes now",
  // slug: "this-is-a-blog",
  date: "2015/07/05",
  description:
    "Railscasts theme in css and scss using pygments compatible class names",
  content: `
A recent very cool addition to the growing arsenal of css tricks is css shapes. This makes it possible for you to wrap text around objects with another shape than square. We all know how to float an image to the left or right of some text, but if you want to have magazine-style floating, where the text follows a shape in an image, then this might be for you.

The only problem is that - at the time of writing this blog post - it is only supported in webkit and opera. So it will run natively on Chrome, Safari and Opera, but the other browsers just shrug their shoulders.

Luckily the guys at Adobe created a js polyfill for achieving this. It will let you use this technique in all browsers (except IE, lower than 10). You can find the polyfill here: https://github.com/adobe-webplatform/css-shapes-polyfill.

In the repositry you will find a .js (normal) and min.js (minified) version. Load either file like you’d reference any script file, and the script automatically loads and fixes your images that uses css shapes.

That will allow you to do this, cross-browser:

\`\`\`html
<div class="floated-text">
  <img src="http://placehold.it/200x200">
  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
</div>
\`\`\`

and the css:

\`\`\`css
.floated-text {
  width: 400px;
}

.floated-text img {
  float: left;
  border-radius: 50%;
  -webkit-shape-outside: circle(); /* this is for earlier versions of Chrome */
  shape-outside: circle();
}
\`\`\`

By using the above code, the text will float alongside an invisible circle, instead of the normal straight square floating text. More css shapes are supported.

The scripts is a polyfill, so browsers that support the css are left alone. For the rest, a technique using a lot of div’s with different offsets are used. Not the most elegant solution, but I can’t think of a better way to do it, and it works.

This allows you to do some really nifty things with layout. I recommend you check out the article at MDN for more details on the css shape-outside spec: https://developer.mozilla.org/en-US/docs/Web/CSS/shape-outside. If you fancy a longer read, check out the specs at W3C: http://dev.w3.org/csswg/css-shapes/.
      `,
  tags: ["css", "shapes"],
  category: "Programming"
};
