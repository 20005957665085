import { Object3D, Vector3, Euler, EventDispatcher } from "three";

/**
 * @author mrdoob / http://mrdoob.com/
 * @author Mugen87 / https://github.com/Mugen87
 */

const PointerLockControls = function(camera, domElement) {
  var scope = this;

  this.domElement = domElement || document.body;
  this.isLocked = false;

  camera.rotation.set(0, 0, 0);

  var pitchObject = new Object3D();
  pitchObject.add(camera);

  var yawObject = new Object3D();
  yawObject.position.y = 10;
  yawObject.add(pitchObject);

  var PI_2 = Math.PI / 2;

  function onMouseMove(event) {
    if (scope.isLocked === false) return;

    var movementX =
      event.movementX || event.mozMovementX || event.webkitMovementX || 0;
    var movementY =
      event.movementY || event.mozMovementY || event.webkitMovementY || 0;

    yawObject.rotation.y -= movementX * 0.002;
    pitchObject.rotation.x -= movementY * 0.002;

    pitchObject.rotation.x = Math.max(
      -PI_2,
      Math.min(PI_2, pitchObject.rotation.x)
    );
  }

  function onPointerlockChange() {
    if (document.pointerLockElement === scope.domElement) {
      scope.dispatchEvent({ type: "lock" });

      scope.isLocked = true;
    } else {
      scope.dispatchEvent({ type: "unlock" });

      scope.isLocked = false;
    }
  }

  function onPointerlockError() {
    console.error("THREE.PointerLockControls: Unable to use Pointer Lock API");
  }

  this.connect = function() {
    document.addEventListener("mousemove", onMouseMove, false);
    document.addEventListener("pointerlockchange", onPointerlockChange, false);
    document.addEventListener("pointerlockerror", onPointerlockError, false);
  };

  this.disconnect = function() {
    document.removeEventListener("mousemove", onMouseMove, false);
    document.removeEventListener(
      "pointerlockchange",
      onPointerlockChange,
      false
    );
    document.removeEventListener("pointerlockerror", onPointerlockError, false);
  };

  this.dispose = function() {
    this.disconnect();
  };

  this.getObject = function() {
    return yawObject;
  };

  this.getDirection = (function() {
    // assumes the camera itself is not rotated

    var direction = new Vector3(0, 0, -1);
    var rotation = new Euler(0, 0, 0, "YXZ");

    return function(v) {
      rotation.set(pitchObject.rotation.x, yawObject.rotation.y, 0);

      v.copy(direction).applyEuler(rotation);

      return v;
    };
  })();

  this.lock = function() {
    this.domElement.requestPointerLock();
  };

  this.unlock = function() {
    document.exitPointerLock();
  };

  this.connect();
};

PointerLockControls.prototype = Object.create(EventDispatcher.prototype);
PointerLockControls.prototype.constructor = PointerLockControls;

export default PointerLockControls;
