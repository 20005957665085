import React, { Component } from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import "./skills.scss";
import Category from "./Category";
import { withRouter } from "react-router-dom";
import { TransitionGroup } from "react-transition-group";
import { connect } from "react-redux";
import {
  // selectCategory,
  // deselectCategory,
  showMoreSkills
  // moveCategory
} from "../../reducers/skills/actions";
import { addTranslations, t } from "../../helpers/i18n";
import Color from "color";

class Skills extends Component {
  static propTypes = {
    categories: PropTypes.array.isRequired,
    // selectCategory: PropTypes.func.isRequired,
    // deselectCategory: PropTypes.func.isRequired,
    // activeCategory: PropTypes.object,
    showMoreSkills: PropTypes.func.isRequired
    // params: PropTypes.object.isRequired
  };

  deselectCategory = event => {
    if (!event.target.classList.contains("load-more-button"))
      this.props.history.push(t("navigation.urls.skills"));
  };

  componentWillMount() {
    document.body.addEventListener("click", this.deselectCategory);
  }

  componentWillUnmount() {
    document.body.removeEventListener("click", this.deselectCategory);
  }

  renderCategory = (category, index) => {
    const active =
      this.props.match && category.slug === this.props.match.params.slug;
    return (
      <Category
        {...this.props}
        index={index}
        category={category}
        active={active}
        key={index}
      />
    );
  };

  renderCategories() {
    if (!this.props.categories) return null;
    const categories = this.props.categories.map(this.renderCategory);
    return (
      <div className="skills">
        <TransitionGroup>{categories}</TransitionGroup>
      </div>
    );
  }

  render() {
    let style;
    if (this.props.match.params.slug) {
      const category = this.props.categories.find(
        c => c.slug === this.props.match.params.slug
      );
      const color = Color(category.color);
      style = { backgroundColor: color.lighten(0.5).fade(0.5) };
    } else {
      style = {};
    }
    return (
      <div className="skills-container" style={style}>
        <Helmet>
          <title>{t("skills.title")}</title>
          <meta name="description" content={t("skills.description")} />
        </Helmet>
        <div className="page-title">
          <h1>{t("skills.title")}</h1>
        </div>
        {this.renderCategories()}
      </div>
    );
  }
}

addTranslations({
  en: {
    skills: {
      title: "Skills",
      description: "Here you can find an overview of the skills I have"
    }
  },
  nl: {
    skills: {
      title: "Ervaring",
      description: "Een overzicht van alles waar ik ervaring mee heb"
    }
  }
});

const mapStateToProps = state => ({
  categories: state.skills.categories,
  activeCategory: state.skills.activeCategory
});

const mapDispatchToProps = {
  // selectCategory,
  // deselectCategory,
  showMoreSkills
  // moveCategory
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Skills)
);
