import React, { Component } from "react";
import { lang, AVAILABLE_LOCALES } from "../../helpers/i18n";
import classNames from "classnames";
import "./lang.scss";
import config from "../../config/config";

class Lang extends Component {
  renderLink = locale => {
    const url = `//${locale}.${config.domain_name}`;
    const className = classNames("lang-link", { active: locale === lang });
    return (
      <a href={url} alt={locale} className={className} key={locale}>
        {locale}
      </a>
    );
  };
  renderLinks() {
    return AVAILABLE_LOCALES.map(this.renderLink);
  }
  render() {
    return <div className="lang">{this.renderLinks()}</div>;
  }
}

export default Lang;
