import React, { Component } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

class PortfolioLink extends Component {
  state = {
    hover: false
  };

  onMouseOver = () => this.setState({ hover: true });

  onMouseOut = () => this.setState({ hover: false });

  renderToolTip() {
    if (!this.state.hover) return null;
    return <div className="portfolio-link-tooltip">{this.props.item.name}</div>;
  }

  render() {
    const className = classNames("portfolio-link", {
      active: this.props.active
    });
    const { slug } = this.props.item;
    return (
      <Link
        to={`/portfolio/${slug}`}
        className={className}
        onMouseOver={this.onMouseOver}
        onMouseOut={this.onMouseOut}
        onClick={() => this.props.scrollToItem(this.props.index)}
      >
        <div className="bullet" />
        {this.renderToolTip()}
      </Link>
    );
  }
}

export default PortfolioLink;
