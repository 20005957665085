import React, { Component } from "react";
import PropTypes from "prop-types";
import BlogItem from "./BlogItem";
import { TransitionGroup } from "react-transition-group";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import {
  selectItem,
  deselectItem,
  setCategoryFilter,
  setTagFilter,
  setTimelineFilter,
  resetFilters
} from "../../reducers/blog/actions";
import Sidebar from "../Sidebar/Sidebar";
// import { Transition } from "react-spring";
import classNames from "classnames";
import { addTranslations, t } from "../../helpers/i18n";

import "./blog.scss";

class Blog extends Component {
  static propTypes = {
    items: PropTypes.array.isRequired,
    visibleItems: PropTypes.array.isRequired,
    categories: PropTypes.array.isRequired,
    params: PropTypes.object.isRequired,
    tags: PropTypes.array.isRequired,
    timeline: PropTypes.array.isRequired,
    setCategoryFilter: PropTypes.func.isRequired,
    setTagFilter: PropTypes.func.isRequired,
    setTimelineFilter: PropTypes.func.isRequired,
    categoryFilter: PropTypes.string,
    tagFilter: PropTypes.string,
    timelineFilter: PropTypes.string,
    resetFilters: PropTypes.func.isRequired
  };

  state = {
    sidebarActive: false
  };

  renderItems() {
    if (this.props.visibleItems.length) {
      const items = this.props.visibleItems.map((item, index) => {
        return <BlogItem item={item} {...this.props} key={index} />;
      });
      return (
        <div className="blog-items">
          <TransitionGroup>{items}</TransitionGroup>
        </div>
      );
    }
    return null;
  }

  renderCategory = (category, index) => {
    const className = classNames("category filter-link", {
      active: category === this.props.categoryFilter
    });
    const arg = category === this.props.categoryFilter ? null : category;
    return (
      <li key={index}>
        <Link
          to="/blog"
          className={className}
          onClick={() => this.props.setCategoryFilter(arg)}
        >
          {category}
        </Link>
      </li>
    );
  };

  renderCategories() {
    if (this.props.categories.length) {
      const categories = this.props.categories.map(this.renderCategory);
      return (
        <div className="categories">
          <h3>{t("categories")}</h3>
          <ul className="list-unstyled">{categories}</ul>
        </div>
      );
    }
    return null;
  }

  renderTag = (tag, index) => {
    const className = classNames("filter-link tag", {
      active: tag === this.props.tagFilter
    });
    const arg = tag === this.props.tagFilter ? null : tag;
    return (
      <Link
        to="/blog"
        className={className}
        key={index}
        onClick={() => this.props.setTagFilter(arg)}
      >
        {tag}
      </Link>
    );
  };

  renderTags() {
    if (this.props.tags.length) {
      return (
        <div className="tags">
          <h3>{t("tags")}</h3>
          {this.props.tags.map(this.renderTag)}
        </div>
      );
    }
    return null;
  }

  renderTimelineItem = (yearMonth, index) => {
    const [year, month] = yearMonth.split("/").map(num => Number(num));
    const className = classNames("filter-link timeline-item", {
      active: yearMonth === this.props.timelineFilter
    });

    const args =
      yearMonth === this.props.timelineFilter ? [null, null] : [year, month];
    return (
      <li key={index}>
        <Link
          to="/blog"
          className={className}
          onClick={() => this.props.setTimelineFilter(...args)}
        >
          {t("month_names")[month - 1]} {year}
        </Link>
      </li>
    );
  };

  renderTimeline() {
    const timelineItems = this.props.timeline.map(this.renderTimelineItem);
    return (
      <div className="timeline">
        <h3>{t("archive")}</h3>
        <ul className="list-unstyled">{timelineItems}</ul>
      </div>
    );
  }

  renderFilters() {
    if (
      !(
        this.props.tagFilter ||
        this.props.categoryFilter ||
        this.props.timelineFilter
      )
    )
      return null;
    return (
      <a
        className="filter-link clear-filters"
        onClick={this.props.resetFilters}
      >
        {t("clear_all_filters")}
      </a>
    );
  }

  toggleSidebar = () => {
    this.setState({ sidebarActive: !this.state.sidebarActive });
  };

  renderSidebarButton() {
    const sidebarButtonClassName = classNames("sidebar-button", {
      active: this.state.sidebarActive
    });
    return (
      <button className={sidebarButtonClassName} onClick={this.toggleSidebar}>
        <div className="line line1" ref="line1" />
        <div className="line line2" ref="line2" />
      </button>
    );
  }

  renderSidebar() {
    return (
      <Sidebar
        open={this.state.sidebarActive}
        toggleMenu={this.toggleSidebar}
        left
      >
        <div className="blog-navigation">
          {this.renderFilters()}
          {this.renderCategories()}
          {this.renderTags()}
          {this.renderTimeline()}
        </div>
      </Sidebar>
    );
  }

  render() {
    const className = classNames("content-container", {
      "sidebar-open": this.state.sidebarActive
    });
    return (
      <div className="blog">
        <div className={className}>
          <div className="content">
            <h1 className="page-title">Blog</h1>
            {this.renderItems()}
          </div>
        </div>
        {this.renderSidebarButton()}
        {this.renderSidebar()}
      </div>
    );
  }
}

addTranslations({
  en: {
    clear_all_filters: "Clear all filters",
    month_names: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ]
  },
  nl: {
    clear_all_filters: "Herstel alle filters",
    month_names: [
      "Januari",
      "Februari",
      "Maart",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Augustus",
      "September",
      "Oktober",
      "November",
      "December"
    ]
  }
});

const mapDispatchToProps = {
  selectItem,
  deselectItem,
  setCategoryFilter,
  setTagFilter,
  setTimelineFilter,
  resetFilters
};

const mapStateToProps = state => ({
  items: state.blog.items,
  visibleItems: state.blog.visibleItems,
  activeItem: state.blog.activeItem,
  categories: state.blog.categories,
  tags: state.blog.tags,
  timeline: state.blog.timeline,
  categoryFilter: state.blog.categoryFilter,
  tagFilter: state.blog.tagFilter,
  timelineFilter: state.blog.timelineFilter
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Blog)
);
