import React, { Component } from "react";
import Navigation from "./Navigation";
import "./Header.scss";
import classNames from "classnames";

class Header extends Component {
  state = {
    menuOpen: false
  };

  toggleMenu = () => {
    this.setState({ menuOpen: !this.state.menuOpen });
  };

  render() {
    const linkClassName = classNames("open-navigation-link", {
      active: this.state.menuOpen
    });
    return (
      <header className="header" ref="container">
        <button className={linkClassName} onClick={this.toggleMenu} ref="link">
          <div className="line line1" ref="line1" />
          <div className="line line2" ref="line2" />
          <div className="line line3" ref="line3" />
        </button>
        <Navigation toggleMenu={this.toggleMenu} open={this.state.menuOpen} />
      </header>
    );
  }
}

export default Header;
