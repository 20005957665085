export default {
  name: "Learn 3d modeling with blender",
  date: "2013/01/21",
  description: "A free course via iTunes U teaching 3d modeling with blender.",
  content: `
Here is a good tip for those of you that always wanted to learn to model 3d objects with blender, but thought that the learning curve was to steep: There is a free online course via iTunes U.

Blender is an amazing free solution for rendering 3d models. It’s capabilities are great, and can compete with other commercial solutions. But the interface is a bit of a hard nut to crack, and the learning curve is steep. Save yourself some time, and go straight to the good stuff with this course. You’ll need to have iTunes installed.

Download [here](https://itunes.apple.com/us/course/3d-design-blender/id548623616)
`,
  tags: ["tutorial", "3d", "itunes u"],
  category: "Tutorials"
};
